import React from 'react'
import { Center, Modal, ModalBody, ModalHeader, Text } from '@retailer-platform/shared-components'
import { useDashMessages } from '../../../intl/intl.hooks'
import { ForceCurrentICPlatformVersion } from './controls/ForceCurrentICPlatformVersion'
import { ShowNavAccessControl } from './controls/ShowNavAccessControl'
import { AccountDetails } from './controls/AccountDetails'
import { ShowContactHeader } from './controls/ShowContactHeader'

export interface AdminControlsModalProps {
  isOpen: boolean
  onClose: () => void
}

export const AdminControlModal = (props: AdminControlsModalProps) => {
  const i18n = useDashMessages({
    title: 'app.admin.controlPanel.title',
    footer: 'app.admin.controlPanel.footer',
  })

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.onClose} maxHeight={'unset'}>
      <ModalHeader>{i18n.title}</ModalHeader>

      <ModalBody>
        <AccountDetails />
        <ShowNavAccessControl />
        <ShowContactHeader />
        <ForceCurrentICPlatformVersion />
      </ModalBody>

      <ModalBody>
        <Center fillParent>
          <Text size="small">{i18n.footer}</Text>
        </Center>
      </ModalBody>
    </Modal>
  )
}
