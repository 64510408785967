import { useMemo } from 'react'
import { useOptionalWarehouseContext } from '@retailer-platform/dashboard/utils'
import { useListRetailerCollectionsV3Query } from './index'

export const INSTACART_RETAILER_ID = '4'

export const useListRetailerCollectionsV3 = ({
  slugs,
  enabled = true,
}: {
  slugs: string[]
  enabled?: boolean
}) => {
  const warehouseContext = useOptionalWarehouseContext()
  const retailerId = warehouseContext
    ? warehouseContext?.warehouse?.id.toString()
    : INSTACART_RETAILER_ID

  const { data, ...rest } = useListRetailerCollectionsV3Query({
    fetchPolicy: 'cache-first',
    skip: !enabled,
    variables: {
      retailerId,
      searchTerm: '',
      limit: '100',
      offset: '0',
      slugs,
    },
  })

  const collections = useMemo(
    () =>
      data
        ?.instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_ListCollections
        ?.collections ?? [],
    [data]
  )

  return {
    collections,
    data,
    ...rest,
  }
}
