import { type RPPDomain } from '@retailer-platform/dashboard/core'
import {
  NavEntryPoint,
  NavItem,
  AdminNavEntryPoint,
  AdminNavEntryPointV2,
} from '@retailer-platform/dashboard/routing'
import { storefrontRouter } from './routing/storefrontRouter'
import {
  marketplaceOnlyPageBuilderViewAccessControl,
  pageBuilderViewAccessControl,
  storeConfigurationDraftViewAccessControl,
  slotManagerViewAccessControl,
} from './access-control/storefrontAccess.configuration'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import { autosuggestViewAccessControl } from './access-control/autosuggestAccess.configuration'
import {
  departmentNavigationAccessControl,
  navigationViewAccessControl,
} from './access-control/navigationAccess.configuration'
import { navigationAdminViewAccessControl } from './access-control/storefrontAdminAccess.configuration'
import { storefrontAdminRouter } from './routing/storefrontAdminRouter'
import { storefrontPartnerScopedRouter } from './routing/storefrontPartnerScopedRouter'
import {
  creativeManagerAccessControl,
  creativeManagerV2AccessControl,
} from './access-control/creativeManagerAccess.configuration'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'storefront',
  totem: {
    entity: 'storefront-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    admin: [storefrontAdminRouter],
    scoped: [storefrontRouter],
    partnerScoped: [storefrontPartnerScopedRouter],
  },
  navigationEntries: [
    {
      attachTo: NavItem.Storefront,
      subNavItems: [
        {
          type: 'item',
          labelId: 'storefrontDomain.navigation.page-title',
          route: 'storefront-navigation-header',
          accessControl: navigationViewAccessControl,
          position: 0,
        },
      ],
    },
    {
      attachTo: NavItem.Storefront,
      attachToV2: NavEntryPoint.Marketing,
      subNavItems: [
        {
          type: 'item',
          labelId: 'storefrontDomain.creativeManager.nav',
          route: 'storefront-creative-manager',
          accessControl: creativeManagerAccessControl,
          position: 5,
          positionNavV2: 2,
        },
        {
          type: 'item',
          labelId: 'storefrontDomain.slotManager.pageTitle',
          route: 'storefront-slots-manager',
          accessControl: slotManagerViewAccessControl,
          position: 10,
          positionNavV2: 10,
        },
        {
          type: 'item',
          labelId: 'storefrontDomain.creativeManagerV2.nav',
          route: 'storefront-creative-manager-v2',
          accessControl: creativeManagerV2AccessControl,
          position: 8,
          positionNavV2: 3,
        },
      ],
    },
    {
      attachTo: NavItem.Storefront,
      attachToV2: NavEntryPoint.Merchandising,
      subNavItems: [
        {
          type: 'item',
          labelId: 'storefrontDomain.brand-settings.page-title',
          route: 'storefront-brand-settings',
          subRoutes: ['storefront-custom-images'],
          accessControl: storeConfigurationDraftViewAccessControl,
          position: 2,
          positionNavV2: 4,
        },
        {
          type: 'item',
          labelId: 'storefrontDomain.contentPages.dashboard.pageTitle',
          route: 'storefront-content-pages-dashboard',
          accessControl: pageBuilderViewAccessControl,
          position: 3,
          positionNavV2: 5,
        },
        {
          type: 'item',
          labelId: 'storefrontDomain.homepage.dashboard.pageTitle',
          route: 'storefront-homepage-dashboard',
          accessControl: pageBuilderViewAccessControl,
          position: 4,
          positionNavV2: 6,
        },
        {
          type: 'item',
          labelId: 'storefrontDomain.autosuggest-links-list.page-title',
          route: 'storefront-autosuggest-links-list',
          accessControl: autosuggestViewAccessControl,
          position: 6,
          positionNavV2: 7,
        },
        {
          type: 'item',
          labelId: 'storefrontDomain.contentPages.dashboard.pageTitle.marketplace',
          route: 'storefront-content-pages-dashboard',
          accessControl: marketplaceOnlyPageBuilderViewAccessControl,
          position: 7,
          positionNavV2: 8,
        },
        {
          type: 'item',
          labelId: 'storefrontDomain.navigation.collections.title',
          route: 'storefront-navigation-collections',
          accessControl: departmentNavigationAccessControl,
          position: 1,
          positionNavV2: 1,
        },
      ],
    },
  ],
  adminNavigationEntries: [
    {
      attachTo: AdminNavEntryPoint.LandingPageSystem,
      attachToV2: AdminNavEntryPointV2.Merchandising,
      subNavItems: [
        {
          route: 'storefront-admin-navigation-footer',
          labelId: 'storefrontDomain.navigation.footer.mp.nav.title',
          accessControl: navigationAdminViewAccessControl,
          positionNavV2: 0,
        },
      ],
    },
  ],
}

export default definition
