import React, { type FunctionComponent } from 'react'
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { Popover } from '@mantine/core'
import { ChevronDownIcon } from '@instacart/ids-core'
import { colors } from '@retailer-platform/shared-components'
import { useIntl } from 'react-intl'
import { MenuItemTitleContainer } from '../NavMenuTop/NavMenuTopL1Item'
import { useNavContext } from '../../../nav/utils/NavContext'
import { type NavMenuHierarchy } from '../../NavMenuHierarchy'

type Props = {
  setCurrentlySelectedItem: (value: number) => void
  title: string
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  navMenuHierarchy: NavMenuHierarchy
}

export const UserMenu: FunctionComponent<React.PropsWithChildren<Props>> = ({
  children,
  setCurrentlySelectedItem,
  title,
  isOpen,
  setIsOpen,
  navMenuHierarchy,
}) => {
  const { setMostRecentClickedRoute } = useNavContext()
  const intl = useIntl()

  const color = isOpen ? '#0A5546' : '#FAF1E5'

  return (
    <>
      <Popover
        opened={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
        onOpen={() => {
          setCurrentlySelectedItem(null)
          setIsOpen(true)
        }}
        styles={{ dropdown: { padding: '16px 0px' } }}
        width={300}
        position="bottom"
        offset={0}
        shadow="md"
        keepMounted
        middlewares={{ shift: { mainAxis: true, padding: { left: 0 } } }}
      >
        <Popover.Target>
          <div css={{ display: 'flex', alignItems: 'center' }} onClick={() => setIsOpen(!isOpen)}>
            <MenuItemTitleContainer
              currentlySelected={isOpen}
              color={isOpen ? colors.PRIMARY_TDS.DARK : colors.GRAYSCALE.X0}
              backgroundColor={isOpen ? colors.GRAYSCALE.X0 : ''}
              hoverColor={navMenuHierarchy.navBarColorHover}
              customPadding="8px 8px 8px 16px"
            >
              {intl.formatMessage({ id: title })}
              <ChevronDownIcon
                size={16}
                css={{
                  fill: color,
                  transition: 'transform 0.2s ease',
                  transform: isOpen ? 'rotate(180deg)' : '',
                }}
              />
            </MenuItemTitleContainer>
          </div>
        </Popover.Target>
        <Popover.Dropdown>{children}</Popover.Dropdown>
      </Popover>
    </>
  )
}
