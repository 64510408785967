import styled from '@emotion/styled'
import { colors, fontWeight, spacing } from '@retailer-platform/shared-components'
import { css } from '@emotion/react'
import React, { type FunctionComponent, useMemo } from 'react'
import { Subtitle } from '@retailer-platform/dashboard/gin-and-tonic'

export const Page = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
})

export const Container = styled.div({
  display: 'flex',
  alignItems: 'baseline',
  flexDirection: 'column',
  gap: `${spacing.X20}`,
  padding: `${spacing.X20}`,
  paddingTop: `${spacing.X0}`,
  flexGrow: 1,
  width: '100%',
  height: '100%',
})

export const Row = styled.div({
  margin: spacing.X0,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  width: '100%',
})

export const HorizontalLayout = styled.div({
  margin: spacing.X0,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'flex-start',
})

export const subtitleStyles = css({
  whiteSpace: 'nowrap',
  marginLeft: 0,
  lineHeight: '32px',
  fontWeight: fontWeight.SEMIBOLD,
})

export const labelStyles = css({
  whiteSpace: 'nowrap',
  lineHeight: '30px',
  fontWeight: fontWeight.REGULAR,
  fontSize: '10px',
  color: colors.SECONDARY.REGULAR,
})

// @ts-ignore
export const FilterTitle = ({ children }) => (
  <Subtitle size={'medium'} css={subtitleStyles}>
    {children}
  </Subtitle>
)

// @ts-ignore
export const FilterLabel = ({ children }) => <div css={labelStyles}>{children}</div>

export const Filter = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

export const FilterContent = styled.div({
  display: 'flex',
  height: '100%',
  width: '100%',
})

export const FilterContainer = styled.div({
  paddingBottom: `${spacing.X16}`,
  display: 'flex',
  flexDirection: 'column',
  borderBottom: `1px solid ${colors.GRAYSCALE.X20}`,
  width: '100%',
  maxHeight: '60%',
  gap: `${spacing.X16}`,
})

export const ActionSheetDropdownCss = css({
  display: 'flex',
  flexDirection: 'column',
  zIndex: 9,
})

export const Column = styled.div({
  flex: '0 0 auto',
  display: 'flex',
  flexDirection: 'column',
  flexBasis: 'content !important',
  maxWidth: '350px',
  gap: `${spacing.X4}`,
})

export const Divider = styled.hr({
  borderTop: `1px solid ${colors.GRAYSCALE.X20}`,
  height: '0px',
  margin: '0px',
  width: '100%',
})

export const Link = styled.a({
  color: colors.SUCCESS.REGULAR,
})

export const Spacer = styled.div({
  flex: 1,
})

export const TableHeaderColStyle = css({
  overflow: 'auto',
  width: '100%',
  height: '100%',
  paddingLeft: spacing.X16,
  paddingRight: spacing.X16,
  padding: spacing.X12,
  fontWeight: 'bold',
  wordBreak: 'keep-all',
  whiteSpace: 'nowrap',
  verticalAlign: 'middle',
  borderBottom: `4px solid ${colors.GRAYSCALE.X20}`,
  textAlign: 'left',
  minWidth: '100%',
  display: 'flex',
  minHeight: '50px',
  alignItems: 'center',
})

interface TableHeaderColProps {
  minWidth?: number
  sticky?: 'left' | 'right'
  index: number
}

export const TableHeaderCol: FunctionComponent<React.PropsWithChildren<TableHeaderColProps>> = ({
  children,
  minWidth,
  sticky,
  index,
}) => {
  const [left, width] = useMemo(() => calculateLeftAndWidth(!!sticky, index), [index, sticky])

  return (
    <th
      style={{
        verticalAlign: 'top',
        minWidth: minWidth ? `${minWidth}px` : 'unset',
        position: 'sticky',
        top: 0,
        background: 'white',
        zIndex: sticky ? 10 : 9,
        boxShadow: '0 2px 2px -1px rgba(0, 0, 0, 0.4)',
        left: sticky ? `${left}px` : 'inherit',
        width: sticky ? `${width}px` : 'inherit',
      }}
    >
      <div id={`column-${index}`} css={TableHeaderColStyle}>
        {children}
      </div>
    </th>
  )
}

export const TableBodyColStyle = css({
  display: 'flex',
  alignItems: 'center',
  overflow: 'auto',
  padding: spacing.X4,
  paddingLeft: spacing.X16,
  wordBreak: 'keep-all',
  whiteSpace: 'nowrap',
})

interface TableBodyColProps {
  sticky: 'left' | 'right' | undefined
  index: number
}

export const TableBodyCol: FunctionComponent<React.PropsWithChildren<TableBodyColProps>> = ({
  sticky,
  children,
  index,
}) => {
  const [left, width] = useMemo(() => calculateLeftAndWidth(!!sticky, index), [index, sticky])

  return (
    <td
      style={{
        verticalAlign: 'middle',
        overflow: 'auto',
        borderBottom: '1px solid #e8e9eb',
        position: sticky ? 'sticky' : 'inherit',
        left: sticky ? `${left}px` : 'inherit',
        width: sticky ? `${width}px` : 'inherit',
        background: sticky ? 'white' : 'inherit',
        zIndex: sticky ? 8 : 'inherit',
        boxShadow: sticky ? '2px 0px 0px rgba(0, 0, 0, 0.5))' : 'inherit',
      }}
    >
      <div css={TableBodyColStyle}>{children}</div>
    </td>
  )
}

const calculateLeftAndWidth = (sticky: boolean, index: number) => {
  if (!sticky) return [null, null]

  let width = 0
  let left = 0
  for (let i = 0; i < index; i++) {
    const element = document.getElementById(`column-${i}`)
    if (!element) continue

    const bounds = element.getBoundingClientRect()
    left += bounds.width
  }

  const element = document.getElementById(`column-${index}`)
  if (element) {
    const bounds = element.getBoundingClientRect()
    width = bounds.width
  }

  return [left, width]
}

export const TooltipContainer = styled(Row)`
  gap: ${spacing.X8};
`

export const Table: FunctionComponent<
  React.PropsWithChildren<{ columns: React.ReactNode; rows: React.ReactNode }>
> = ({ columns, rows }) => (
  <table style={{ width: '100%', border: `none` }}>
    <thead>
      <tr>{columns}</tr>
    </thead>
    <tbody>{rows}</tbody>
  </table>
)
