import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { NewSelect, spacing } from '@retailer-platform/shared-components'
import { components, type MenuListProps } from 'react-select-5'
import styled from '@emotion/styled'
import { useDomainMessages } from '../../../utils/domain/intl'
import {
  type RetailerCollectionSearchArgs,
  useGetRetailerCollections,
} from '../../../api/getRetailerCollections.hooks'
import { collectionSelectorAdvancedAccessControl } from '../../../access-control/storefrontAccess.configuration'
import { useDomainAccessControl } from '../../../utils/domain/accessControl'
import SelectorOption from './SelectorOption'
import SelectorMenuList from './SelectorMenuList'
import { useCollectionPills } from './pillFilter/useCollectionPills'
import AdvancedSearchModal from './advancedSearch/AdvancedSearchModal'
import { type CollectionType, type OptionData } from './types'
import { useSearchTerm } from './useSearchTerm'
import { getUniqueCollections } from './utils'

export enum CollectionSelectionValue {
  CollectionId = 'collectionId',
  CollectionSlug = 'collectionSlug',
}

export interface CollectionSelectorProps extends React.ComponentProps<typeof NewSelect> {
  collectionTypes?: RetailerCollectionSearchArgs['collectionTypes']
  initialSlug?: string
  isDisabled?: boolean
  initialCollections?: OptionData[]
  additionalOptions?: { label: string; value: string }[]
  showTags?: boolean
  excludeDynamicRetailerCollections?: boolean
  selectionValueType?: CollectionSelectionValue
  setCollectionId?: (value: string) => void
  retailerId?: string
  advancedSearch?: boolean
}

const Container = styled.div({
  '> *': {
    lineHeight: spacing.X24,
  },
})

const CollectionSelector = ({
  initialSlug = '',
  initialCollections = [],
  isDisabled = false,
  additionalOptions,
  showTags = true,
  excludeDynamicRetailerCollections,
  collectionTypes = ['retailer_collection'],
  selectionValueType = CollectionSelectionValue.CollectionSlug,
  retailerId,
  advancedSearch = true,
  ...props
}: CollectionSelectorProps) => {
  const i18n = useDomainMessages({
    placeholder: 'storefrontDomain.collections-selector.placeholder',
    noOptionsMessage: 'storefrontDomain.collections-selector.advancedSearch.empty',
    typeToSearchMessage: 'storefrontDomain.collections-selector.advancedSearch.typeToSearch',
  })
  const hasAccess = useDomainAccessControl()
  const advancedView = !!advancedSearch && hasAccess(collectionSelectorAdvancedAccessControl)

  // We need to keep track of the selected options so that we can make sure they are always available in the dropdown regardless of the search term
  const [selectedOptionsCache, setSelectedOptionsCache] = useState<OptionData[]>(initialCollections)
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false)

  const { pillOptions, selectedPills, togglePill, getCollectionTypes } = useCollectionPills({
    collectionTypes: collectionTypes as CollectionType[],
    enablePills: advancedView,
  })

  const { setSearch, getDebouncedSlugs, getDebouncedSearchTerm, shouldPerformSearch, setSlugs } =
    useSearchTerm()

  useEffect(() => {
    setSlugs([initialSlug ?? ''])
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const { apiResult, apiLoading } = useGetRetailerCollections({
    ...(retailerId && { retailerId }),
    searchTerm: getDebouncedSearchTerm(),
    skip: !shouldPerformSearch && !initialSlug,
    collectionTypes: getCollectionTypes(),
    slugs: getDebouncedSlugs(),
    excludeDynamicRetailerCollections,
  })

  useEffect(() => {
    // set selected option cache if it's not already set and api result is not empty
    if (selectedOptionsCache.length === 0 && apiResult && apiResult.length > 0) {
      const matchingCollection = apiResult.find(
        collection =>
          (selectionValueType === CollectionSelectionValue.CollectionId &&
            collection.id === props.value) ||
          (selectionValueType === CollectionSelectionValue.CollectionSlug &&
            collection.slug === props.value)
      )

      if (matchingCollection) {
        setSelectedOptionsCache([
          {
            ...matchingCollection,
            label: matchingCollection.name ?? '',
            value:
              selectionValueType === CollectionSelectionValue.CollectionId
                ? matchingCollection.id ?? ''
                : matchingCollection.slug ?? '',
          },
        ])
      }
    }
  }, [apiResult, selectionValueType, props.value, selectedOptionsCache])

  const toggleAdvancedSearch = useCallback(() => setIsAdvancedSearchOpen(prev => !prev), [])

  const handleAdvancedSearchChange = useCallback(
    (selectedOption: OptionData) => {
      setSelectedOptionsCache([selectedOption])
      props.onChange?.(selectedOption.value, selectedOption)
    },
    [props]
  )

  const options: OptionData[] = useMemo(
    () =>
      (apiResult ?? []).map(x => ({
        ...x,
        label: x.name ?? '',
        value:
          selectionValueType === CollectionSelectionValue.CollectionId ? x.id ?? '' : x.slug ?? '',
      })),
    [apiResult, selectionValueType]
  )

  // Update the options prop in the NewSelect component
  const menuListComponent = useMemo(
    () => (menuListProps: MenuListProps<OptionData>) =>
      advancedView ? (
        <SelectorMenuList
          {...menuListProps}
          pillOptions={pillOptions}
          selectedPills={selectedPills}
          onPillSelect={togglePill}
          onToggleAdvancedSearch={toggleAdvancedSearch}
        />
      ) : (
        <components.MenuList {...menuListProps} />
      ),
    [advancedView, pillOptions, selectedPills, togglePill, toggleAdvancedSearch]
  )

  // Look at removing this block and use the search in new select
  const filteredAdditionalOptions: OptionData[] = useMemo(() => {
    if (!additionalOptions || !shouldPerformSearch) return []

    const searchTerm = getDebouncedSearchTerm()?.toLowerCase() ?? ''
    return additionalOptions.filter(o => o.label.toLowerCase().includes(searchTerm))
  }, [additionalOptions, getDebouncedSearchTerm, shouldPerformSearch])

  return (
    <Container>
      <NewSelect
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        components={
          {
            Option: showTags ? SelectorOption : components.Option,
            MenuList: menuListComponent,
          } as any
        }
        // Add selected options here so they are always shown in the selected bar
        options={getUniqueCollections([
          ...selectedOptionsCache,
          ...filteredAdditionalOptions,
          ...options,
        ])}
        css={{ zIndex: 100 }}
        isLoading={apiLoading}
        onInputChange={(value, meta) => {
          // Don't clear search if a value is being set as it causes the dropdown to close
          if (meta?.action === 'set-value' && !value) return
          return setSearch(value)
        }}
        placeholder={i18n.placeholder}
        noOptionsMessage={({ inputValue }) =>
          inputValue ? i18n.noOptionsMessage : i18n.typeToSearchMessage
        }
        {...props}
        onChange={(value, options) => {
          // need to cast to OptionData for setCollectionId
          const collectionResult = options as OptionData
          if (props?.setCollectionId && collectionResult?.id) {
            props.setCollectionId(collectionResult.id)
          }

          if (props.onChange) {
            props.onChange(value, options)
          }

          setSelectedOptionsCache(Array.isArray(options) ? options : [options])
        }}
        isDisabled={isDisabled}
      />
      {advancedView && isAdvancedSearchOpen && (
        <AdvancedSearchModal
          onClose={toggleAdvancedSearch}
          onChange={handleAdvancedSearchChange}
          initialSearchTerm={getDebouncedSearchTerm()}
          // original props from parent
          value={props.value}
          selectedCollectionCache={selectedOptionsCache[0] || null}
          collectionTypes={collectionTypes as CollectionType[]}
          initialSlug={initialSlug}
          retailerId={retailerId}
          excludeDynamicRetailerCollections={excludeDynamicRetailerCollections}
          selectionValueType={selectionValueType}
          additionalOptions={additionalOptions}
        />
      )}
    </Container>
  )
}

CollectionSelector.defaultProps = {
  collectionTypes: ['retailer_collection'],
  showTags: true,
  advancedSearch: true,
}

export default CollectionSelector
