import { useState, useCallback, useMemo } from 'react'
import { useDebouncedState } from '@retailer-platform/shared-components'

const MIN_COLLECTION_SEARCH_TERM_LENGTH = 3

export const useSearchTerm = (initialSearchTerm: string = '') => {
  const [searchTerm, debouncedSearchTerm, setSearchTerm] = useDebouncedState(initialSearchTerm, 200)
  const [slugs, setSlugs] = useState<string[] | undefined>()

  const searchTermIsSlug = useMemo(
    () => debouncedSearchTerm.startsWith('rc-') || debouncedSearchTerm.startsWith('n-'),
    [debouncedSearchTerm]
  )

  const shouldPerformSearch = debouncedSearchTerm.length >= MIN_COLLECTION_SEARCH_TERM_LENGTH

  const getDebouncedSlugs = useCallback(() => {
    if (searchTermIsSlug) return [debouncedSearchTerm.replace('rc-', '').replace('n-', '')]
    if (debouncedSearchTerm.length || !slugs) return
    return slugs.filter(Boolean).map(s => s.replace('rc-', '').replace('n-', ''))
  }, [searchTermIsSlug, debouncedSearchTerm, slugs])

  const getDebouncedSearchTerm = useCallback(() => {
    if (searchTermIsSlug) return
    return debouncedSearchTerm
  }, [searchTermIsSlug, debouncedSearchTerm])

  const setSearch = useCallback(
    (value: string) => {
      setSearchTerm(value)
    },
    [setSearchTerm]
  )

  return {
    slugs,
    searchTerm,
    setSlugs,
    setSearch,
    getDebouncedSlugs,
    getDebouncedSearchTerm,
    shouldPerformSearch,
  }
}
