import { type OptionData } from './types'
import { type CollectionWithCount } from './advancedSearch/useRetailerCollectionsWithCounts'

export function getUniqueCollections<T extends CollectionWithCount | OptionData>(
  collections: T[]
): T[] {
  const seen = new Set<string>()
  return collections
    .slice()
    .reverse()
    .filter(collection => {
      const key = 'id' in collection ? collection.id ?? '' : collection.slug ?? ''
      if (seen.has(key)) return false
      seen.add(key)
      return true
    })
    .reverse()
}
