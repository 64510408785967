const EN_US = {
  'connectedStoresAdminDomain.caper.title': 'Caper',
  'connectedStoresAdminDomain.caper.pageTitle': 'Connected Stores Caper Page',
  'connectedStoresAdminDomain.caper.cartSubtitle': 'carts',
  'connectedStoresAdminDomain.caper.configsTitle': 'Configs',
  'connectedStoresAdminDomain.caper.devicesTitle': 'Devices',
  'connectedStoresAdminDomain.caper.partnerTitle': 'Partner',
  'connectedStoresAdminDomain.caper.retailerTitle': 'Retailer',
  'connectedStoresAdminDomain.caper.retailerPartnersTitle': 'Retail Partners',
  'connectedStoresAdminDomain.caper.storeTitle': 'Store',
  'connectedStoresAdminDomain.caper.toastTitle': 'Config Updated',
  'connectedStoresAdminDomain.caper.toastAction': 'Dismiss',
  'connectedStoresAdminDomain.caper.cancelAction': 'Cancel',
  'connectedStoresAdminDomain.caper.deleteAction': 'Delete',
  'connectedStoresAdminDomain.caper.deleteTitle': 'Delete Config',
  'connectedStoresAdminDomain.caper.unknownEntity': 'Unknown',
  'connectedStoresAdminDomain.caper.save': 'Save',
  'connectedStoresAdminDomain.caper.environmentBannerProd': 'Production Caper Environment',
  'connectedStoresAdminDomain.caper.environmentBannerStg': 'Staging Caper Environment',
  'connectedStoresAdminDomain.shelfLabels.title': 'Shelf Labels',
  'connectedStoresAdminDomain.upos.title': 'UPOS',
  'connectedStoresAdminDomain.upos.status.integrationStatus': 'Integration Status',
  'connectedStoresAdminDomain.upos.status.retailer': 'Retailer',
  'connectedStoresAdminDomain.upos.status.location': 'Location',
  'connectedStoresAdminDomain.upos.status.integration': 'Integration',
  'connectedStoresAdminDomain.upos.status.status': 'Status',
  'connectedStoresAdminDomain.upos.status.since': 'Since',
  'connectedStoresAdminDomain.upos.status.lastChecked': 'Last Checked',
  'connectedStoresAdminDomain.upos.status.healthy': 'Healthy',
  'connectedStoresAdminDomain.upos.status.unhealthy': 'Unhealthy',
  'connectedStoresAdminDomain.upos.status.undetermined': 'N/A',
} as const

export default EN_US
