import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { typography } from '@instacart/tds'
import { ModalBody, ModalHeader } from '@retailer-platform/shared-components'
import { useSurvey } from '../RetailerSatisfactionSurveyContext'
import { useDashMessages } from '../../../../intl/intl.hooks'
import ConfirmationCheckMark from '../../../../../src/assets/ConfirmationCheckmark.png'
import {
  SURVEY_SUCCESS_SLIDE_DURATION_MS,
  type UserFeedbackSurveyPages,
  type UserFeedbackSurveyState,
  type UserFeedbackSurveyProps,
} from './UserFeedbackSurveyController'

const SurveyConfirmationImage = styled.img({
  width: '18%',
  height: '18%',
  objectFit: 'contain',
  marginBottom: '16px',
})

const SurveyConfirmationContainer = styled.div({
  ...typography.subtitle,
})

const SurveyConfirmationBody = styled.div({
  ...typography.bodyRegular,
})

const UserFeedbackSurveyPage2 = () => {
  const { toolProps } = useSurvey<
    UserFeedbackSurveyPages,
    UserFeedbackSurveyState,
    UserFeedbackSurveyProps
  >()

  useEffect(() => {
    if (toolProps) {
      const timeout = setTimeout(() => {
        toolProps.onClose()
      }, SURVEY_SUCCESS_SLIDE_DURATION_MS)

      return () => clearTimeout(timeout)
    }
  }, [toolProps])

  const messages = useDashMessages({
    title: 'userFeedback.survey.page2.title',
    subheading: 'userFeedback.survey.page2.subheading',
    body: 'userFeedback.survey.page2.body',
  })

  return (
    <>
      <ModalHeader>{messages.title}</ModalHeader>
      <ModalBody
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          minHeight: '252px',
        }}
      >
        <SurveyConfirmationImage src={ConfirmationCheckMark} />
        <SurveyConfirmationContainer>{messages.subheading}</SurveyConfirmationContainer>
        <SurveyConfirmationBody>{messages.body}</SurveyConfirmationBody>
      </ModalBody>
    </>
  )
}

export default UserFeedbackSurveyPage2
