import { useMemo } from 'react'
import { useRetailerId } from '@retailer-platform/dashboard/routing'
import { useGetRetailerCollections } from '../../../../api/getRetailerCollections.hooks'
import { useListRetailerCollectionsV3 } from '../../../../api/listRetailerCollectionsV3.hooks'
import { type Collection, type CollectionType } from '../types'
import { useDomainMessages } from '../../../../utils/domain/intl'

interface UseRetailerCollectionsWithCountsProps {
  retailerId?: string
  searchTerm?: string
  slugs?: string[]
  shouldPerformSearch?: boolean
  collectionTypes?: CollectionType[]
  excludeDynamicRetailerCollections?: boolean
}

export interface CollectionWithCount extends Collection {
  productCount?: number
}

export const useRetailerCollectionsWithCounts = ({
  retailerId: retailerIdInput,
  searchTerm,
  slugs,
  collectionTypes,
  shouldPerformSearch,
  excludeDynamicRetailerCollections,
}: UseRetailerCollectionsWithCountsProps) => {
  const i18n = useDomainMessages({
    dynamic: 'storefrontDomain.collections-selector.advancedSearch.columns.dynamic',
  })
  // Fallback to the retailer ID from the dashboard routing if not provided
  const retailerIdResult = useRetailerId({ throwOnMissing: false })
  const retailerId = retailerIdInput || retailerIdResult

  // Fetch collections based on the provided criteria
  const { apiResult: collectionsResult, apiLoading: collectionsLoading } =
    useGetRetailerCollections({
      retailerId,
      searchTerm,
      skip: !shouldPerformSearch && !slugs,
      slugs,
      collectionTypes: collectionTypes ?? [],
      excludeDynamicRetailerCollections,
      useFullNamePath: true,
    })

  // Extract slugs for collections that start with 'rc-'
  const slugsToFetch = useMemo(
    () =>
      collectionsResult
        ?.filter(collection => collection.slug && collection.slug.startsWith('rc-'))
        .map(collection => collection.slug?.replace('rc-', '') ?? '') ?? [],
    [collectionsResult]
  )

  // Fetch product counts for the filtered collections
  const { collections: countData, loading: isCountLoading } = useListRetailerCollectionsV3({
    slugs: slugsToFetch,
    enabled: !!retailerId && slugsToFetch.length > 0,
  })

  // Combine collection data with product counts
  const collectionsWithCounts: CollectionWithCount[] = useMemo(() => {
    // Create a map of slug to product count
    const countMap = new Map(
      countData?.map(item => [
        item.slug,
        item.dynamicallyFedStatic ? i18n.dynamic : item.productCount,
      ])
    )

    // Merge collection data with product counts
    return (
      collectionsResult?.map(collection => ({
        ...collection,
        productCount: (() => {
          const count = countMap.get(`${collection.slug?.replace('rc-', '') ?? ''}`)
          if (count === i18n.dynamic) return undefined
          return count ? Number(count) : undefined
        })(),
      })) ?? []
    )
  }, [collectionsResult, countData, i18n])

  return {
    collectionsWithCounts,
    isLoading: collectionsLoading || isCountLoading,
  }
}
