import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  RadioWithLabel,
  TextArea,
  type ModalProps,
  spacing,
  ModalContainer,
} from '@retailer-platform/shared-components'
import React, { type FunctionComponent, useState, useMemo } from 'react'
import { DividerBase, Text } from '@instacart/ids-customers'
import styled from '@emotion/styled'
import { ReviewAction } from '../../api'
import { useDomainMessages } from '../../utils/domain/intl'
import { useIntegrationConfig } from '../integration-config-provider/IntegrationConfigProvider'
import { type UserPermittedActions } from '../../api/get-user-permitted-actions/api'
import {
  ExtraTextContentGrid,
  type ExtraTextContentGridProps,
} from '../extra-text-content-grid/ExtraTextContentGrid'

export type OnReviewAddSubmitProps = {
  action: ReviewAction
  comment: string
}

export type SubmitFeedbackModalProps = {
  onSubmit: (props: OnReviewAddSubmitProps) => void
  loading?: boolean
  defaultAction?: ReviewAction
  userPermittedActions?: UserPermittedActions
  extraContent?: React.ReactNode
  extraTextContent?: ExtraTextContentGridProps
} & ModalProps

export const SubmitFeedbackModal: FunctionComponent<
  React.PropsWithChildren<SubmitFeedbackModalProps>
> = (props: SubmitFeedbackModalProps) => {
  const i18n = useDomainMessages({
    title: 'approvalManagementLibrary.addReviewModal.title',
    comment: 'approvalManagementLibrary.actions.comment',
    cancel: 'approvalManagementLibrary.actions.cancel',
    approve: 'approvalManagementLibrary.actions.approve',
    reject: 'approvalManagementLibrary.actions.reject',
    submit: 'approvalManagementLibrary.actions.submit',
    commentDescription: 'approvalManagementLibrary.addReviewModal.commentDescription',
    approveDescription: 'approvalManagementLibrary.addReviewModal.approveDescription',
    approveBreakGlass: 'approvalManagementLibrary.addReviewModal.approveBreakGlass',
    approveBreakGlassDescription:
      'approvalManagementLibrary.addReviewModal.approveBreakGlassDescription',
    approveAndPublish: 'approvalManagementLibrary.addReviewModal.approveAndPublish',
    approveAndPublishDescription:
      'approvalManagementLibrary.addReviewModal.approveAndPublishDescription',
    requestChanges: 'approvalManagementLibrary.addReviewModal.requestChanges',
    requestChangesDescription: 'approvalManagementLibrary.addReviewModal.requestChangesDescription',
    rejectDescription: 'approvalManagementLibrary.addReviewModal.rejectDescription',
    leaveAComment: 'approvalManagementLibrary.actions.leaveAComment',
    optional: 'approvalManagementLibrary.general.optional',
    noActionsPermitted: 'approvalManagementLibrary.addReviewModal.noActionsPermitted',
  })

  const config = useIntegrationConfig()
  const { userPermittedActions } = props
  const { canApprove, canComment, canPublish, canRevise, canCancel } = userPermittedActions || {
    canApprove: true,
    canComment: true,
    canPublish: true,
    canRevise: true,
    canCancel: true,
  }

  const [action, setAction] = useState<ReviewAction | undefined>(props.defaultAction || undefined)
  const [comment, setComment] = useState<string>('')

  const commentBox = (
    <CommentBoxContainer id="commentBoxContainer">
      <Row>
        <Text typography="bodyMedium1">{i18n.leaveAComment}</Text>
        {action !== ReviewAction.Comment && <Text typography="bodyMedium2">{i18n.optional}</Text>}
      </Row>
      <TextArea
        data-testid="comment"
        placeholder={i18n.comment}
        value={comment}
        css={{ minHeight: 80 }}
        autoFocus
        onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
          setComment(e.target.value)
        }
      />
    </CommentBoxContainer>
  )

  const commentRadioButton = canComment && (
    <RadioButton
      data-testid="commentRadioButton"
      action={ReviewAction.Comment}
      label={i18n.comment}
      isChecked={action === ReviewAction.Comment}
      description={i18n.commentDescription}
      setAction={setAction}
      commentBox={commentBox}
    />
  )

  const approveRadioButtonProps = useMemo(() => {
    if (config?.autoPublishOnApprove) {
      return {
        'data-testid': 'approveAndPublishRadioButton',
        action: ReviewAction.ApprovalAndPublish,
        label: i18n.approveAndPublish,
        isChecked: action === ReviewAction.ApprovalAndPublish,
        description: i18n.approveAndPublishDescription,
      }
    }

    return {
      'data-testid': 'approveRadioButton',
      action: ReviewAction.Approval,
      label: i18n.approve,
      isChecked: action === ReviewAction.Approval,
      description: i18n.approveDescription,
    }
  }, [action, config?.autoPublishOnApprove, i18n])

  const approveRadioButton = canApprove && (
    <RadioButton {...approveRadioButtonProps} setAction={setAction} commentBox={commentBox} />
  )

  const breakGlassPublishRadioButton = canPublish && config?.allowBreakGlassPublish && (
    <RadioButton
      data-testid="approveBreakGlassRadioButton"
      action={ReviewAction.BreakGlassPublish}
      label={i18n.approveBreakGlass}
      isChecked={action === ReviewAction.BreakGlassPublish}
      description={i18n.approveBreakGlassDescription}
      setAction={setAction}
      commentBox={commentBox}
    />
  )
  const requestChangesRadioButton = canRevise && (
    <RadioButton
      data-testid="requestChangesRadioButton"
      action={ReviewAction.RequestChanges}
      label={i18n.requestChanges}
      isChecked={action === ReviewAction.RequestChanges}
      description={i18n.requestChangesDescription}
      setAction={setAction}
      commentBox={commentBox}
    />
  )
  const rejectRadioButton = canCancel && (
    <RadioButton
      data-testid="rejectRadioButton"
      action={ReviewAction.Cancel}
      label={i18n.reject}
      isChecked={action === ReviewAction.Cancel}
      description={i18n.rejectDescription}
      setAction={setAction}
      commentBox={commentBox}
    />
  )

  const noActionsPermitted =
    !commentRadioButton &&
    !approveRadioButton &&
    !breakGlassPublishRadioButton &&
    !requestChangesRadioButton &&
    !rejectRadioButton
  const noActionsFallbackText = noActionsPermitted && (
    <Text typography="bodyMedium1">{i18n.noActionsPermitted}</Text>
  )

  const extraContent =
    props.extraContent ||
    (props.extraTextContent && <ExtraTextContentGrid {...props.extraTextContent} />)

  return (
    <Modal {...props} maxHeight="90%" style={{ overlay: { display: 'flex' } }}>
      <ModalContainer>
        <ModalHeader>{i18n.title}</ModalHeader>

        {extraContent && (
          <>
            <StyledModalBody>{extraContent}</StyledModalBody>
            <DividerBase />
          </>
        )}

        <StyledModalBody>
          {commentRadioButton}
          {approveRadioButton}
          {breakGlassPublishRadioButton}
          {requestChangesRadioButton}
          {rejectRadioButton}
          {noActionsFallbackText}
        </StyledModalBody>

        <ModalFooter>
          <Button
            variant="secondary"
            name="cancel"
            data-testid="cancel"
            onClick={props.onRequestClose}
          >
            {i18n.cancel}
          </Button>
          <Button
            variant="primary"
            name="submit"
            data-testid="submit"
            loading={props.loading}
            onClick={() => props.onSubmit({ action: action!, comment: comment })}
            disabled={noActionsPermitted || !action}
          >
            {i18n.submit}
          </Button>
        </ModalFooter>
      </ModalContainer>
    </Modal>
  )
}

const RadioButton = ({
  action,
  description,
  isChecked,
  label,
  setAction,
  commentBox,
  ...props
}: {
  action: ReviewAction
  label: string
  isChecked: boolean
  description: string
  setAction: (action: ReviewAction) => void
  commentBox?: JSX.Element
}) => (
  <>
    <div>
      <RadioWithLabel
        id={action}
        value={action}
        label={label}
        checked={isChecked}
        onChange={() => setAction(action)}
        {...props}
      />
      <Text css={{ marginLeft: 30 }} typography="bodySmall2">
        {description}
      </Text>
    </div>
    {isChecked && commentBox}
  </>
)

const StyledModalBody = styled(ModalBody)({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.X16,
  overflowY: 'auto',
  flexShrink: 1,
})

const CommentBoxContainer = styled.div({
  marginLeft: 30,
})

const Row = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: spacing.X4,
})
