import React, { type ComponentProps, type FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { useIntl } from 'react-intl'
import { colors } from '@retailer-platform/shared-components'
import { type IconComponentType } from '@instacart/ids-core'
import { OpenInIcon } from '@instacart/ids-core'
import { typography, elevation } from '@instacart/tds'
import { NavLink } from './NavLink'
import { TextContainer } from './NavItem'

type Props = {
  titleMessage?: string
  HeaderIcon: IconComponentType
  description?: string
  descriptionLink?: string
  titleSize?: 'small' | 'large'
  customPadding?: string
  isNarrow?: boolean
  withLowShadow?: boolean
}

const HeaderTextContainer = styled.div({
  display: 'flex',
  alignItems: 'flex-end',
  padding: '0px 0px 8px',
  gap: '8px',
})

const HeaderText = styled.div<{ small: boolean }>(
  {
    fontStyle: 'normal',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    color: colors.GRAYSCALE.X70,
  },
  ({ small }) => ({
    ...(small ? typography.subtitle : typography.title),
  })
)

const DescriptionText = styled.div({
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  display: 'flex',
  alignItems: 'center',
  padding: '8px 0px 8px 0px',
  color: colors.GRAYSCALE.X70,
})

const RootContainer = styled.div(
  ({ customPadding, withLowShadow }: { customPadding: string; withLowShadow: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: customPadding ? customPadding : '',
    ...(withLowShadow && elevation.low.shadow),
  })
)

const LearnMoreText = styled(TextContainer)({
  cursor: 'pointer',
  gap: '8px',
})

export const L1Header: FunctionComponent<React.PropsWithChildren<Props>> = ({
  HeaderIcon,
  titleMessage,
  description,
  descriptionLink,
  titleSize,
  customPadding,
  isNarrow,
  withLowShadow,
}) => {
  const intl = useIntl()

  const iconSize = titleSize == 'small' ? 24 : 32

  return (
    <RootContainer customPadding={customPadding} withLowShadow={withLowShadow}>
      <HeaderTextContainer>
        <HeaderIcon size={iconSize} color="systemGrayscale70" />
        <HeaderText small={titleSize == 'small'}>{titleMessage}</HeaderText>
      </HeaderTextContainer>
      {description && (
        <div
          css={{
            display: 'flex',
            flexDirection: isNarrow ? 'column' : 'row',
            gap: isNarrow ? '0px' : '8px',
            alignItems: isNarrow ? 'left' : 'center',
          }}
        >
          <DescriptionText>{intl.formatMessage({ id: description })}</DescriptionText>
          {descriptionLink && (
            <NavLink newTab={true} href={descriptionLink}>
              <LearnMoreText>
                Learn more
                <OpenInIcon css={{ fill: '#0A5546' }} size={16} />
              </LearnMoreText>
            </NavLink>
          )}
        </div>
      )}
    </RootContainer>
  )
}
