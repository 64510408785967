import { type FunctionComponent, type PropsWithChildren } from 'react'
import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { NavEntryPoint, NavItem, usePartnerId } from '@retailer-platform/dashboard/routing'
import { clientEnv } from '@retailer-platform/dashboard/env'
import { widgetsRouter } from './routing/widgetsRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import { widgetsConfigViewAccessControl } from './access-control/widgetsAccess.configuration'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'

const enabledPartnerIds: string[] = [
  // The Garden
  '289',
]

const NavItemWrapper: FunctionComponent<PropsWithChildren> = ({ children }) => {
  // On production, display the nav item only for the selected partners.
  const isProduction = clientEnv.PUBLIC_CLIENT_ENVIRONMENT === 'production'
  const partnerId = usePartnerId()
  if (isProduction && !enabledPartnerIds.includes(partnerId)) {
    return null
  }
  return children
}

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'widgets',
  totem: {
    entity: 'widgets-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    scoped: [widgetsRouter],
  },
  navigationEntries: [
    {
      attachTo: NavItem.Storefront,
      attachToV2: NavEntryPoint.Developer,
      subNavItems: [
        {
          type: 'item',
          route: 'widgets-config',
          accessControl: widgetsConfigViewAccessControl,
          labelId: 'widgetsDomain.title',
          position: 11,
          positionNavV2: 8,
          isNew: true,
          NavItemWrapper,
        },
      ],
    },
  ],
}

export default definition
