import { useParams } from 'react-router'
import { useRetailerId } from '@retailer-platform/dashboard/routing'
import { useGetRetailerCollectionsQuery } from './index'

const INSTACART_RETAILER_ID = '4'
export interface RetailerCollectionSearchArgs {
  searchTerm?: string
  skip?: boolean
  collectionTypes: (
    | 'retailer_collection'
    | 'department'
    | 'hub'
    | 'retailer_department'
    | 'holiday'
  )[]
  slugs?: string[]
  excludeDynamicRetailerCollections?: boolean
  excludedSourceTypes?: string[]
  retailerId?: string
  useFullNamePath?: boolean
}

export const useGetRetailerCollections = ({
  searchTerm,
  skip = false,
  collectionTypes = ['retailer_collection'],
  slugs,
  excludeDynamicRetailerCollections = false,
  excludedSourceTypes = ['flyer', 'flyer_activity'],
  retailerId: retailerIdInput,
  useFullNamePath = false,
}: RetailerCollectionSearchArgs) => {
  const retailerIdResult = useRetailerId({ throwOnMissing: false })
  const { storeConfigId } = useParams<{ storeConfigId: string }>()
  const retailerId = retailerIdInput || retailerIdResult

  const { data, error, loading, refetch } = useGetRetailerCollectionsQuery({
    fetchPolicy: 'cache-and-network',
    skip,
    variables: {
      searchTerm,
      retailerId,
      collectionTypes,
      slugs,
      excludeDynamicRetailerCollections,
      excludedSourceTypes,
      storeConfigurationId: storeConfigId,
      useFullNamePath,
    },
  })

  return {
    searchRetailerCollections: refetch,
    apiResult: data?.collectionsServiceGetCollections?.collections,
    apiSuccess: Boolean(data),
    apiLoading: loading,
    apiError: error,
  }
}
