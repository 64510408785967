import React, { useState, useEffect } from 'react'
import { navV2EducationPrimaryModalData } from './NavV2EducationData'
import NavV2EducationContent from './NavV2EducationContent'
import { trackEvent } from '../../../../utils/events/trackEvent'
import { useCurrentAccountContext } from '../../../../exports/utils'

export type NavV2EducationModalProps = {
  onClose: () => void
}

const PAGE_TO_PAGE_NUMBER = {
  PRIMARY: 0,
  SECONDARY: 1,
} as const

type PageNumber = (typeof PAGE_TO_PAGE_NUMBER)[keyof typeof PAGE_TO_PAGE_NUMBER]

const NavV2EducationModal = ({ onClose }: NavV2EducationModalProps) => {
  const accountContext = useCurrentAccountContext()

  useEffect(() => {
    if (accountContext?.account?.id) {
      trackEvent({
        id: 'app.navV2.feature_launch_modal.viewed',
        description: 'Tracks whenever the NavV2Education Modal is viewed',
        data: {
          accountID: accountContext?.account?.id,
        },
      })
    }
  }, [accountContext?.account?.id])

  const [pageNumber, setPageNumber] = useState<PageNumber>(PAGE_TO_PAGE_NUMBER.PRIMARY)
  const pageData = navV2EducationPrimaryModalData[pageNumber]

  const onClickPrimary = () => {
    if (pageNumber === PAGE_TO_PAGE_NUMBER.PRIMARY) {
      setPageNumber(PAGE_TO_PAGE_NUMBER.SECONDARY)
    } else {
      onClose()
    }
  }

  return <NavV2EducationContent content={pageData} onClickPrimary={onClickPrimary} />
}

export default NavV2EducationModal
