import { useNavContext } from '../../../../gin-and-tonic/containers/nav/utils/NavContext'

export const useNavV2EducationModalAccess = () => {
  const { hadOptedOutNavV2, allowNavV1RouletteEnabled } = useNavContext()

  const shouldOpenModal = () => {
    return hadOptedOutNavV2 && !allowNavV1RouletteEnabled
  }

  return shouldOpenModal
}
