import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import { creativeManagerV2AccessControl } from '../access-control/creativeManagerAccess.configuration'

// @hygen:inject page-import
const StorefrontCreativeManagerPageV2 = lazy(
  () =>
    import(
      /* webpackChunkName: "StorefrontCreativeManagerPageV2" */
      '../pages/creative-manager/CreativeManagerPageV2'
    )
)

export const storefrontPartnerScopedRouter = (
  <DomainRoute route="storefront-partner-scoped">
    <DashSwitch>
      <DomainRoute
        exact
        route="storefront-creative-manager-v2"
        component={StorefrontCreativeManagerPageV2}
        accessControl={creativeManagerV2AccessControl}
      />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
