import { useGoToPathFactory, useScopedPathnameFactory } from '@retailer-platform/dashboard/routing'
import { type DomainRouteName } from '../../routing/routes'
import { type ContentTypes } from '../../integrationConfigurations'

export const useDomainScopedPathname = useScopedPathnameFactory<DomainRouteName>()
export const useDomainGoToPath = useGoToPathFactory<DomainRouteName>()

export const useGoToApprovalRequestDetails = (
  approvalRequestId: string,
  contentType: ContentTypes
) => {
  if (contentType === 'data_ingestion_column_maps') {
    return useDomainGoToPath('app-admin-data-ingestion-approval-request/show', {
      approvalRequestId,
    })
  }

  return undefined
}
