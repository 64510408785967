import NavV2PrimarySplashImage from '../../../../assets/featureHighlights/nav-v2-primary-splash.png'
import NavV2SecondarySplashImage from '../../../../assets/featureHighlights/nav-v2-secondary-feedback-splash.avif'
import { type DashMessages } from '../../../../intl/intl.types'

export type NavV2EducationDataType = {
  image: string
  title: DashMessages
  subheading: DashMessages
  body: NavV2EducationSection[]
  alertMessageText?: DashMessages
  primaryButtonText?: DashMessages
  secondaryButtonText?: DashMessages
  onClickPrimary?: () => void
  onClickSecondary?: () => void
}

export enum NavV2EducationSectionTypes {
  LIST = 'list',
  BODY = 'body',
}

export type NavV2EducationSectionEntry = {
  text?: DashMessages
}

type NavV2EducationSection = {
  sectionType: NavV2EducationSectionTypes
  contents: NavV2EducationSectionEntry[]
}

export const navV2EducationPrimaryModalData: NavV2EducationDataType[] = [
  {
    image: NavV2PrimarySplashImage,
    title: 'navV2.announcement.welcomeModal.title',
    subheading: 'navV2.announcement.welcomeModal.subheading',
    body: [
      {
        sectionType: NavV2EducationSectionTypes.LIST,
        contents: [
          {
            text: 'navV2.announcement.welcomeModal.body1',
          },
          {
            text: 'navV2.announcement.welcomeModal.body2',
          },
          {
            text: 'navV2.announcement.welcomeModal.body3',
          },
          {
            text: 'navV2.announcement.welcomeModal.body4',
          },
        ],
      },
    ],
    alertMessageText: 'navV2.announcement.welcomeModal.alert',
    primaryButtonText: 'navV2.announcement.welcomeModal.primaryButton',
  },
  {
    image: NavV2SecondarySplashImage,
    title: 'navV2.announcement.switchNavigationModal.title',
    subheading: 'navV2.announcement.switchNavigationModal.subheading',
    body: [
      {
        sectionType: NavV2EducationSectionTypes.BODY,
        contents: [
          {
            text: 'navV2.announcement.switchNavigationModal.body1',
          },
        ],
      },
      {
        sectionType: NavV2EducationSectionTypes.BODY,
        contents: [
          {
            text: 'navV2.announcement.switchNavigationModal.body2',
          },
        ],
      },
    ],
    primaryButtonText: 'navV2.announcement.switchNavigationModal.primaryButton',
  },
]
