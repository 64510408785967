import { AnalyticsBrowser } from '@segment/analytics-next'
import { datadogRum } from '@datadog/browser-rum'
import clientEnv from '../global/clientEnv'
import { isDebugMode } from '../core/factories/events/eventDebugging'
import { type GlobalAnalytics } from '../core/factories/events/segmentAnalytics.types'
import { errors } from '../error-handling/errors'

export const initAnalytics = () => {
  try {
    Array.from(new Array(localStorage.length))
      .map((_, i) => localStorage.key(i))
      .filter(key => key.startsWith('amplitude_unsent'))
      .forEach(key => localStorage.removeItem(key))
  } catch (err) {
    errors.captureException(err, 'failed to clear amplitude_unsent events')
  }

  if (clientEnv.PUBLIC_CLIENT_SEGMENT_TOKEN) {
    let apiHost = 'analytics.dashboard-api.instacart.com/v1'
    let writeKey = clientEnv.PUBLIC_CLIENT_SEGMENT_TOKEN

    // Check both are set to avoid race conditions on load
    if (clientEnv.PUBLIC_CLIENT_MONGOOSE_WRITE_KEY && clientEnv.PUBLIC_CLIENT_SEGMENT_API_HOST) {
      apiHost = `${clientEnv.PUBLIC_CLIENT_SEGMENT_API_HOST}/v2`
      writeKey = clientEnv.PUBLIC_CLIENT_MONGOOSE_WRITE_KEY
    }

    const analytics: AnalyticsBrowser = AnalyticsBrowser.load(
      {
        writeKey,
        // we proxy the initial load of segment cdn to get around adblockers blocking the segment url
        // this base url is used to load the *initial* javascript package from Segment that will allow
        // events to be sent (aka the 3rd party packaged code)
        cdnURL: 'https://analyticscdn.dashboard-api.instacart.com',
      },
      {
        integrations: {
          'Segment.io': {
            // This is the host that events will get *sent* to after the package has loaded that allows us
            // to send events. Write key must match the corresponding event ingestor it's being sent to.
            // eg. analytics.dashboard-api.instacart.com/v1 -> Segment.io provided URL
            // eg. ipp-mgs.instacart.team -> IPP's Mongoose ingestor for staging
            apiHost,
            protocol: 'https',
          },
        },
      }
    )

    const userAgentMiddleWare = function ({ payload, next }) {
      // We currently do not support sending userAgentData to segment
      if (payload?.obj?.context?.userAgentData) {
        delete payload.obj.context.userAgentData
      }
      next(payload)
    }

    analytics.addSourceMiddleware(userAgentMiddleWare)

    const global: GlobalAnalytics = globalThis
    global.analytics = analytics

    analytics.debug(isDebugMode())
  }
}

export const initDataDogRum = () => {
  datadogRum.init({
    applicationId: clientEnv.PUBLIC_CLIENT_DATADOG_RUM_APPLICATION_ID as string,
    clientToken: clientEnv.PUBLIC_CLIENT_DATADOG_RUM_CLIENT_TOKEN as string,
    site: 'datadoghq.com',
    service: 'ipp',
    env: clientEnv.PUBLIC_CLIENT_ENVIRONMENT as string,
    sampleRate: parseInt((clientEnv.PUBLIC_CLIENT_DATADOG_RUM_SAMPLE_RATE as string) || '0', 10),
    trackInteractions: true,
  })
}
