import React from 'react'
import { useIntl } from 'react-intl'
import { type NavV2EducationDataType, NavV2EducationSectionTypes } from './NavV2EducationData'
import styled from '@emotion/styled'
import { Button, spacing, Alert } from '@retailer-platform/shared-components'
import { typography, TDS_DEFAULT_COLORS } from '@instacart/tds'

const ModalContainer = styled.div({
  display: 'flex',
  gap: spacing.X32,
  width: '100%',
  height: '100%',
})

const ModalColumn = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  width: '50%',
})

const ModalContentSection = styled.div({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
})

const ModalButtonFooter = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
})

const SplashImageContainer = styled.div({
  width: '100%',
  height: '100%',
  maxHeight: '424px',
  maxWidth: '427px',
})

const SplashImage = styled.img({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
})

const Headline = styled.div({
  marginTop: '24px',
  ...typography.headline,
})

const Subheading = styled.div({
  ...typography.subtitle,
})

const BodyItem = styled.div({
  ...typography.bodyRegular,
})

const UnorderedList = styled.ul({
  ...typography.bodyRegular,
  paddingLeft: spacing.X20,
})

const ListItem = styled.li({
  ...typography.bodyRegular,
  listStyleType: 'disc',
})

const BodyItemWrapper = styled.div({
  display: 'inline',
})

const PrimaryButton = styled(Button)({
  minWidth: '96px',
  ...typography.bodyEmphasized,
  backgroundColor: TDS_DEFAULT_COLORS.colors.brandPrimaryRegular,
  borderColor: TDS_DEFAULT_COLORS.colors.brandPrimaryRegular,
  '&:hover': {
    borderColor: TDS_DEFAULT_COLORS.colors.brandPrimaryDark,
    backgroundColor: TDS_DEFAULT_COLORS.colors.brandPrimaryDark,
  },
  '&:active': {
    borderColor: TDS_DEFAULT_COLORS.colors.brandPrimaryExtraDark,
    backgroundColor: TDS_DEFAULT_COLORS.colors.brandPrimaryExtraDark,
  },
})

type Props = {
  content: NavV2EducationDataType
  onClickPrimary: () => void
}

const NavV2EducationContent = ({ content, onClickPrimary }: Props) => {
  const intl = useIntl()

  if (!content) {
    return null
  }

  const { image, subheading, title, body, primaryButtonText, alertMessageText } = content

  return (
    <ModalContainer>
      <ModalColumn>
        <SplashImageContainer>
          <SplashImage src={image} />
        </SplashImageContainer>
      </ModalColumn>
      <ModalColumn>
        <ModalContentSection>
          <Headline data-testid="nav-v2-education-title">
            {intl.formatMessage({ id: title })}
          </Headline>
          <Subheading>{intl.formatMessage({ id: subheading })}</Subheading>
          {body.map((section, index) => {
            const { contents, sectionType } = section
            {
              const key = `${sectionType}-${index}`

              return sectionType === NavV2EducationSectionTypes.LIST ? (
                <UnorderedList key={key}>
                  {contents.map(content => (
                    <ListItem key={content.text}>
                      {intl.formatMessage({ id: content.text })}
                    </ListItem>
                  ))}
                </UnorderedList>
              ) : (
                <BodyItemWrapper key={key}>
                  {contents.map(content => (
                    <BodyItem key={content.text}>
                      {intl.formatMessage({ id: content.text })}
                    </BodyItem>
                  ))}
                </BodyItemWrapper>
              )
            }
          })}
          {alertMessageText && (
            <Alert css={typography.bodyEmphasized} variant="info">
              {intl.formatMessage({ id: alertMessageText })}
            </Alert>
          )}
        </ModalContentSection>
        <ModalButtonFooter>
          <PrimaryButton data-testid="nav-v2-education-primary-btn" onClick={onClickPrimary}>
            {intl.formatMessage({ id: primaryButtonText })}
          </PrimaryButton>
        </ModalButtonFooter>
      </ModalColumn>
    </ModalContainer>
  )
}

export default NavV2EducationContent
