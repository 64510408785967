import { transformFilterParamsToApiFilterSelectionsFactory } from '../utils/insightsPortalSchemas.utils'
import { aggCampaignSchemaFilterParamsConfig } from '../agg-warehouse-location-campaign-performance/insightsPortalAggCampaignSchema.hooks'
import {
  createSchemaConfig,
  buildTimeIntervalGroupBysConfig,
  buildTimeIntervalDimensionsConfig,
} from '../helpers/schema.helpers'
import { InsightsFormatterType } from '../types/schema.types'
import { insightsPortalInstacartSiteConfigurationAccessControl } from '../../access-control/insightsPortalInstacartSiteConfigurationAccess.configuration'
import { Metrics, GroupBys, Dimensions } from './fact-campaign-performance.types'

export const schemaConfig = createSchemaConfig({
  metrics: Metrics,
  dimensions: Dimensions,
  defaultFilters: [
    {
      name: Dimensions.IS_INSTACART_STORE_CONFIGURATION,
      values: ['No'],
      accessControlEnablement: insightsPortalInstacartSiteConfigurationAccessControl,
    },
  ],
  metricsConfig: {
    [Metrics.AVG_BASKET_SPEND_WITH_PROMO]: {
      formatter: InsightsFormatterType.Currency,
      label: 'insightsPortalDomain.campaigns.metrics.AVG_BASKET_SPEND_WITH_PROMO',
      tooltip:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.tooltips.AVG_BASKET_SPEND_WITH_PROMO',
    },
    [Metrics.AVG_COST_PER_ACTIVATION]: {
      formatter: InsightsFormatterType.Currency,
      label: 'insightsPortalDomain.campaigns.metrics.AVG_COST_PER_ACTIVATION',
    },
    [Metrics.AVG_COST_PER_ORDER]: {
      formatter: InsightsFormatterType.Currency,
      label: 'insightsPortalDomain.campaigns.metrics.AVG_COST_PER_ORDER',
      tooltip: 'insightsPortalDomain.adsCampaignDashboard.metrics.tooltip.avgCostPerOrder',
    },
    [Metrics.AVG_COST_PER_RESURRECTION]: {
      formatter: InsightsFormatterType.Currency,
      label: 'insightsPortalDomain.campaigns.metrics.AVG_COST_PER_RESURRECTION',
    },
    [Metrics.AVG_COST_PER_USER]: {
      formatter: InsightsFormatterType.Currency,
      label: 'insightsPortalDomain.campaigns.metrics.AVG_COST_PER_USER',
      tooltip:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.tooltips.AVG_COST_PER_USER',
    },
    [Metrics.AVG_COST_PER_USER_ACTIVATED]: {
      formatter: InsightsFormatterType.Currency,
      label: 'insightsPortalDomain.campaigns.metrics.AVG_COST_PER_USER_ACTIVATED',
      tooltip:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.tooltips.AVG_COST_PER_USER_ACTIVATED',
    },
    [Metrics.AVG_COST_PER_USER_REACTIVATED]: {
      formatter: InsightsFormatterType.Currency,
      label: 'insightsPortalDomain.campaigns.metrics.AVG_COST_PER_USER_REACTIVATED',
      tooltip:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.tooltips.AVG_COST_PER_USER_REACTIVATED',
    },
    [Metrics.AVG_COST_PER_USER_RETAINED]: {
      formatter: InsightsFormatterType.Currency,
      label: 'insightsPortalDomain.campaigns.metrics.AVG_COST_PER_USER_RETAINED',
      tooltip:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.tooltips.AVG_COST_PER_USER_RETAINED',
    },
    [Metrics.AVG_DAILY_CAMPAIGN_SPEND]: {
      formatter: InsightsFormatterType.Currency,
      label: 'insightsPortalDomain.campaigns.metrics.AVG_DAILY_CAMPAIGN_SPEND',
      tooltip:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.tooltips.AVG_DAILY_CAMPAIGN_SPEND',
    },
    [Metrics.AVG_UNIT_SPEND_WITH_PROMO]: {
      formatter: InsightsFormatterType.Currency,
      label: 'insightsPortalDomain.campaigns.metrics.AVG_UNIT_SPEND_WITH_PROMO',
      tooltip:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.tooltips.AVG_UNIT_SPEND_WITH_PROMO',
    },
    [Metrics.NUM_ACTIVE_CAMPAIGNS]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.campaigns.metrics.NUM_ACTIVE_CAMPAIGNS',
      tooltip: 'insightsPortalDomain.adsCampaignDashboard.metrics.tooltip.NUM_ACTIVE_CAMPAIGNS',
    },
    [Metrics.NUM_ENDING_SOON_CAMPAIGNS]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.campaigns.metrics.NUM_ENDING_SOON_CAMPAIGNS',
      tooltip: 'insightsPortalDomain.adsCampaignDashboard.metrics.tooltip.campaignEndingSoon',
    },
    [Metrics.NUM_ORDERS_OFFERS]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.campaigns.metrics.NUM_ORDERS_OFFERS',
      tooltip: 'insightsPortalDomain.adsCampaignDashboard.metrics.tooltip.numOrdersOffers',
    },
    [Metrics.REORDER_RATE_28_DAY]: {
      formatter: InsightsFormatterType.Percentage,
      label: 'insightsPortalDomain.campaigns.metrics.REORDER_RATE_28_DAY',
      tooltip:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.tooltips.REORDER_RATE_28_DAY',
    },
    [Metrics.RETURN_ON_AD_SPEND]: {
      formatter: (val: string | number): string => {
        const num = typeof val === 'number' ? val : parseFloat(val)
        if (isNaN(num)) {
          return '-'
        }
        return `${num.toFixed(2)}x`
      },
      label: 'insightsPortalDomain.campaigns.metrics.RETURN_ON_AD_SPEND',
      tooltip: 'insightsPortalDomain.adsCampaignDashboard.metrics.tooltip.roas',
    },
    [Metrics.SUM_ACTIVATIONS]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.campaigns.metrics.SUM_ACTIVATIONS',
      tooltip:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.tooltips.SUM_ACTIVATIONS',
    },

    [Metrics.SUM_ATTRIBUTED_SALES]: {
      formatter: InsightsFormatterType.Currency,
      label: 'insightsPortalDomain.campaigns.metrics.SUM_ATTRIBUTED_SALES',
      tooltip: 'insightsPortalDomain.adsCampaignDashboard.metrics.tooltip.attributedSales',
    },
    [Metrics.SUM_CAMPAIGN_SPEND]: {
      formatter: InsightsFormatterType.Currency,
      label: 'insightsPortalDomain.campaigns.metrics.SUM_CAMPAIGN_SPEND',
      tooltip: 'insightsPortalDomain.adsCampaignDashboard.metrics.tooltip.spend',
    },
    [Metrics.SUM_CAMPAIGN_SPEND_OUTSTANDING]: {
      formatter: InsightsFormatterType.Currency,
      label: 'insightsPortalDomain.campaigns.metrics.SUM_CAMPAIGN_SPEND_OUTSTANDING',
    },

    [Metrics.SUM_ORDERS_OUTSTANDING]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.campaigns.metrics.SUM_ORDERS_OUTSTANDING',
    },

    [Metrics.SUM_RESURRECTIONS]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.campaigns.metrics.SUM_RESURRECTIONS',
      tooltip:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.tooltips.SUM_RESURRECTIONS',
    },
    [Metrics.SUM_USERS]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.campaigns.metrics.SUM_USERS',
      tooltip: 'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.tooltips.SUM_USERS',
    },
    [Metrics.COUNT_USERS]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.campaigns.metrics.COUNT_USERS',
      tooltip: 'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.tooltips.COUNT_USERS',
    },
    [Metrics.COUNT_USERS_ACTIVATED]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.campaigns.metrics.COUNT_USERS_ACTIVATED',
      tooltip:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.tooltips.COUNT_USERS_ACTIVATED',
    },
    [Metrics.COUNT_USERS_REACTIVATED]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.campaigns.metrics.COUNT_USERS_REACTIVATED',
      tooltip:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.tooltips.COUNT_USERS_REACTIVATED',
    },
    [Metrics.COUNT_USERS_RETAINED]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.campaigns.metrics.COUNT_USERS_RETAINED',
      tooltip:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.tooltips.COUNT_USERS_RETAINED',
    },
    [Metrics.SUM_USERS_OFFERS]: {
      label: 'insightsPortalDomain.campaigns.metrics.SUM_USERS_OFFERS',
      formatter: InsightsFormatterType.Integer,
    },
    [Metrics.LAST_ETL_UPDATE]: {
      private: true,
      formatter: InsightsFormatterType.Date,
    },
    [Metrics.NUM_DISTINCT_CAMPAIGNS]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.campaigns.metrics.NUM_DISTINCT_CAMPAIGNS',
      tooltip: 'insightsPortalDomain.adsCampaignDashboard.metrics.tooltip.NUM_DISTINCT_CAMPAIGNS',
    },
    [Metrics.REDEMPTION_RATE]: {
      formatter: InsightsFormatterType.Percentage,
      label: 'insightsPortalDomain.campaigns.metrics.REDEMPTION_RATE',
      tooltip:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.tooltips.REDEMPTION_RATE',
    },
    [Metrics.SUM_APPLICATIONS]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.campaigns.metrics.SUM_APPLICATIONS',
      tooltip:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.tooltips.SUM_APPLICATIONS',
    },
    [Metrics.SUM_REDEMPTIONS]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.campaigns.metrics.SUM_REDEMPTIONS',
      tooltip:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.tooltips.SUM_REDEMPTIONS',
    },
  },
  dimensionsConfig: {
    [Dimensions.CAMPAIGN]: {
      label: 'insightsPortalDomain.campaigns.groupBys.CAMPAIGN_NAME',
    },
    [Dimensions.CAMPAIGN_ID]: {
      label: 'insightsPortalDomain.campaigns.groupBys.CAMPAIGN_ID',
    },
    [Dimensions.CAMPAIGN_ENDING_SOON]: {
      label: 'insightsPortalDomain.filters.campaignEndingSoon.label',
    },
    [Dimensions.DIM_CAMPAIGN_NAME]: {
      label: 'insightsPortalDomain.adsCampaignDashboard.table.header.campaignName',
    },
    [Dimensions.DIM_CAMPAIGN_START_DATE]: {
      formatter: InsightsFormatterType.Date,
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.groupBys.CAMPAIGN_START_DATE',
      date: true,
    },
    [Dimensions.DIM_CAMPAIGN_END_DATE]: {
      formatter: InsightsFormatterType.Date,
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.groupBys.CAMPAIGN_END_DATE',
      date: true,
    },
    [Dimensions.DIM_CAMPAIGN_OBJECTIVE]: {
      label: 'insightsPortalDomain.campaigns.groupBys.CAMPAIGN_OBJECTIVE',
    },
    [Dimensions.DIM_DISCOVERABILITY]: {
      label: 'insightsPortalDomain.campaigns.groupBys.DISCOVERABILITY',
    },
    [Dimensions.DIM_CAMPAIGN_STATUS]: {
      label: 'insightsPortalDomain.campaigns.groupBys.CAMPAIGN_STATUS',
    },
    [Dimensions.CAMPAIGN_TYPE]: {
      label: 'insightsPortalDomain.campaigns.groupBys.CAMPAIGN_TYPE',
    },
    [Dimensions.TAAS_USER_SEGMENT_CAMPAIGN_TYPE]: {
      label: 'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.groupBys.USER_SEGMENT',
    },
    [Dimensions.DIM_USER_SEGMENT]: {
      label: 'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.groupBys.USER_SEGMENT',
    },
    [Dimensions.DIM_USER_SEGMENT_CAMPAIGN_TYPE]: {
      label: 'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.groupBys.USER_SEGMENT',
    },
    [Dimensions.USER_SEGMENT_CAMPAIGN_TYPE]: {
      label: 'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.groupBys.USER_SEGMENT',
    },
    [Dimensions.ORDER_SOURCE]: {
      label: 'insightsPortalDomain.campaigns.groupBys.ORDER_SOURCE',
    },
    [Dimensions.INSIGHTS_PORTAL_ORDER_SOURCE]: {
      label: 'insightsPortalDomain.campaigns.groupBys.ORDER_SOURCE',
    },
    [Dimensions.TAAS_USER_SEGMENT]: {
      label: 'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.groupBys.USER_SEGMENT',
    },
    [Dimensions.IS_INSTACART_STORE_CONFIGURATION]: {
      label: 'insightsPortalDomain.campaigns.groupBys.IS_INSTACART_STORE_CONFIGURATION',
      private: true,
    },
    [Dimensions.DIM_STORE_CONFIGURATION]: {
      label: 'insightsPortalDomain.campaigns.groupBys.DIM_STORE_CONFIGURATION_ID',
    },
    [Dimensions.WAREHOUSE]: {
      label: 'insightsPortalDomain.creatives.filters.retailer',
    },
    [Dimensions.WAREHOUSE_LOCATION]: {
      label: 'insightsPortalDomain.campaigns.groupBys.WAREHOUSE_LOCATION',
    },
    [Dimensions.WAREHOUSE_LOCATION_CODE]: {
      label: 'insightsPortalDomain.campaigns.groupBys.WAREHOUSE_LOCATION_CODE',
    },
    [Dimensions.WAREHOUSE_LOCATION_REGION]: {
      label: 'insightsPortalDomain.campaigns.groupBys.WAREHOUSE_LOCATION_REGION',
    },
    [Dimensions.WAREHOUSE_LOCATION_STATE]: {
      label: 'insightsPortalDomain.campaigns.groupBys.WAREHOUSE_LOCATION_STATE',
    },
    [Dimensions.ZONE_STATE]: {
      label: 'insightsPortalDomain.campaigns.groupBys.ZONE_STATE',
    },
    ...buildTimeIntervalDimensionsConfig,
  },

  // TODO: deprecate
  filterConfigs: aggCampaignSchemaFilterParamsConfig,
  transformFilterParamsToApiFilterSelections: transformFilterParamsToApiFilterSelectionsFactory(
    aggCampaignSchemaFilterParamsConfig
  ),
  groupBys: GroupBys,
  groupBysConfig: {
    [GroupBys.CAMPAIGN_ID]: {
      label: 'insightsPortalDomain.campaigns.groupBys.CAMPAIGN_ID',
    },
    [GroupBys.DIM_CAMPAIGN_NAME]: {
      label: 'insightsPortalDomain.adsCampaignDashboard.table.header.campaignName',
    },
    [GroupBys.DIM_CAMPAIGN_START_DATE]: {
      formatter: InsightsFormatterType.Date,
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.groupBys.CAMPAIGN_START_DATE',
    },
    [GroupBys.DIM_CAMPAIGN_END_DATE]: {
      formatter: InsightsFormatterType.Date,
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.groupBys.CAMPAIGN_END_DATE',
    },
    [GroupBys.DIM_CAMPAIGN_OBJECTIVE]: {
      label: 'insightsPortalDomain.campaigns.groupBys.CAMPAIGN_OBJECTIVE',
    },
    [GroupBys.DIM_DISCOVERABILITY]: {
      label: 'insightsPortalDomain.campaigns.groupBys.DISCOVERABILITY',
    },
    [GroupBys.DIM_CAMPAIGN_STATUS]: {
      label: 'insightsPortalDomain.campaigns.groupBys.CAMPAIGN_STATUS',
    },
    [GroupBys.CAMPAIGN_TYPE]: {
      label: 'insightsPortalDomain.campaigns.groupBys.CAMPAIGN_TYPE',
    },
    ...buildTimeIntervalGroupBysConfig,

    [GroupBys.TAAS_USER_SEGMENT_CAMPAIGN_TYPE]: {
      label: 'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.groupBys.USER_SEGMENT',
    },
    [GroupBys.DIM_USER_SEGMENT_CAMPAIGN_TYPE]: {
      label: 'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.groupBys.USER_SEGMENT',
    },
    [GroupBys.USER_SEGMENT_CAMPAIGN_TYPE]: {
      label: 'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.groupBys.USER_SEGMENT',
    },
    [GroupBys.ORDER_SOURCE]: {
      label: 'insightsPortalDomain.campaigns.groupBys.ORDER_SOURCE',
    },
    [GroupBys.TAAS_USER_SEGMENT]: {
      label: 'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.groupBys.USER_SEGMENT',
    },
    [GroupBys.WAREHOUSE_LOCATION]: {
      label: 'insightsPortalDomain.campaigns.groupBys.WAREHOUSE_LOCATION',
    },
    [GroupBys.WAREHOUSE_LOCATION_CODE]: {
      label: 'insightsPortalDomain.campaigns.groupBys.WAREHOUSE_LOCATION_CODE',
    },
    [GroupBys.WAREHOUSE_LOCATION_REGION]: {
      label: 'insightsPortalDomain.campaigns.groupBys.WAREHOUSE_LOCATION_REGION',
    },
    [GroupBys.WAREHOUSE_LOCATION_STATE]: {
      label: 'insightsPortalDomain.campaigns.groupBys.WAREHOUSE_LOCATION_STATE',
    },
    [GroupBys.ZONE_STATE]: {
      label: 'insightsPortalDomain.campaigns.groupBys.ZONE_STATE',
    },
  },
})

export { Metrics, GroupBys }
