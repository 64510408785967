import React from 'react'
import Modal from '../../../../../components/Modal'
import styled from '@emotion/styled'
import { Alert, Button, Checkbox, Text } from '@retailer-platform/shared-components'
import { InputBase } from '@instacart/ids-tooling'
import { colors } from 'ic-snacks'
import { useCreateTempOverride } from '../../../../../sections/catalog/graphql/createTempOverride.hooks'
import { type NotificationsContextValue } from '../../../../../contexts/notifications/NotificationsContext'
import { FormattedMessage } from 'react-intl'

interface Props extends NotificationsContextValue {
  onClose(boolean?): void
  items: Options[]
  initialItemIds: number[]
  productId: string
  retailerId: string
  localeId: number
  surfaceId: number
}

interface Options {
  itemId: number
  displayName: string
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem;
  height: 590px;
  overflow: hidden;
  padding: 1px;
`

const ScrollContainer = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
  padding: 0 1rem;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  row-gap: 1rem;
`

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: stretch;
  white-space: nowrap;
`

const FilterBoxSize = styled.div`
  min-height: 4rem;
  max-height: 4rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  border-bottom: 1px solid ${colors.GRAY_93};
  padding: 0 0 1rem 0;
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

const PrimaryButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const AddTempOverridePatchModal = ({
  productId,
  retailerId,
  surfaceId,
  localeId,
  onClose,
  items,
  initialItemIds,
  notifyError,
}: Props) => {
  const [selectedItemIds, setSelectedItemIds] = React.useState<Set<number>>(new Set(initialItemIds))
  const [searchText, setSearchText] = React.useState('')
  const [limitedToActive, setLimitedToActive] = React.useState(false)

  const allSelected = selectedItemIds.size === items.length

  const { createTempOverrideCall, loading, error, called, success } = useCreateTempOverride()

  const surfaceLabel = React.useMemo(() => {
    if (surfaceId === 2) {
      return <FormattedMessage id="productsDomain.view.details.surfaces.storefrontPro" />
    }
    return <FormattedMessage id="productsDomain.view.details.surfaces.marketplace" />
  }, [surfaceId])

  React.useEffect(() => {
    if (success && called) {
      onClose(true)
      window.location.reload()
    }
  }, [success, called, onClose])

  const handleSubmit = React.useCallback(() => {
    const itemsArr = Array.from(selectedItemIds)
    const tombstones = initialItemIds.filter(i => !selectedItemIds.has(i))
    createTempOverrideCall(productId, retailerId, surfaceId, localeId, itemsArr, tombstones)
  }, [
    createTempOverrideCall,
    initialItemIds,
    localeId,
    productId,
    retailerId,
    selectedItemIds,
    surfaceId,
  ])

  const handleAllCheckboxClick = React.useCallback(() => {
    if (allSelected) {
      setSelectedItemIds(new Set())
    } else {
      setSelectedItemIds(
        items.reduce((set, ia) => {
          set.add(ia.itemId)
          return set
        }, new Set<number>())
      )
    }
  }, [allSelected, items])

  const handleIACheckboxClick = React.useCallback(
    (iaId: number) => {
      if (selectedItemIds.has(iaId)) {
        selectedItemIds.delete(iaId)
        setSelectedItemIds(new Set(selectedItemIds))
      } else {
        selectedItemIds.add(iaId)
        setSelectedItemIds(new Set(selectedItemIds))
      }
    },
    [selectedItemIds]
  )

  const filteredIAs = React.useMemo(
    () =>
      items
        ?.filter(ia => ia.displayName?.toLowerCase()?.includes(searchText.toLowerCase()))
        ?.filter(ia => (limitedToActive ? selectedItemIds.has(ia?.itemId) : true)),
    [items, limitedToActive, searchText, selectedItemIds]
  )

  React.useEffect(() => {
    if (error && !loading) {
      notifyError(
        <FormattedMessage id="productsDomain.view.storeLocations.override.modal.error.text" />
      )
    }
  }, [notifyError, error, loading])

  return (
    <Modal onClose={onClose} height={800}>
      {(
        Header: React.ComponentType<React.PropsWithChildren<unknown>>,
        Body: React.ComponentType<React.PropsWithChildren<unknown>>,
        Footer: React.ComponentType<React.PropsWithChildren<unknown>>
      ) => [
        <Header>
          <div>
            <FormattedMessage id="productsDomain.view.storeLocations.override.modal.title" />
            <Text size="small" css={{ margin: '4px 0 -12px 0' }}>
              <FormattedMessage id="productsDomain.view.storeLocations.override.modal.subtitle" />{' '}
              {surfaceLabel}
            </Text>
          </div>
        </Header>,
        <Body>
          <Container>
            <Alert variant={'warning'}>
              <FormattedMessage id="productsDomain.view.storeLocations.override.modal.warning.text" />
            </Alert>
            <FilterBoxSize>
              <InputBase
                id="emergency-patch-search"
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
                placeholder="Search Store Location"
              />
              <Center>
                <Text weight="bold" size="small">
                  <FormattedMessage id="productsDomain.view.storeLocations.override.modal.only.selected.filter" />
                </Text>
                <Checkbox
                  checked={limitedToActive}
                  onChange={() => setLimitedToActive(!limitedToActive)}
                />
              </Center>
            </FilterBoxSize>
            {filteredIAs.length ? (
              <ScrollContainer>
                <Grid>
                  <Text weight="bold">Store Location</Text>
                  <FlexColumn>
                    <Text weight="bold">Made unavailable</Text>
                    <FlexRow>
                      <Text size="small">Select all</Text>
                      <Checkbox checked={allSelected} onChange={handleAllCheckboxClick} />
                    </FlexRow>
                  </FlexColumn>
                  {filteredIAs.map(ia => (
                    <React.Fragment>
                      <div>{ia.displayName}</div>
                      <Center>
                        <Checkbox
                          checked={selectedItemIds.has(ia.itemId)}
                          onChange={() => handleIACheckboxClick(ia.itemId)}
                        />
                      </Center>
                    </React.Fragment>
                  ))}
                </Grid>
              </ScrollContainer>
            ) : (
              <Center>
                <Text weight="bold" size="large">
                  <FormattedMessage id="productsDomain.view.storeLocations.override.modal.no.store" />
                </Text>
              </Center>
            )}
          </Container>
        </Body>,
        <Footer>
          <ButtonRow>
            <Button variant="secondary" onClick={onClose}>
              <FormattedMessage id="productsDomain.view.storeLocations.override.modal.cancel.button" />
            </Button>
            <PrimaryButtons>
              <Text weight="bold">{selectedItemIds.size} selected</Text>
              <Button
                variant="primary"
                loading={called && loading}
                disabled={called && loading}
                onClick={handleSubmit}
              >
                <FormattedMessage id="productsDomain.view.storeLocations.override.modal.submit.button" />
              </Button>
            </PrimaryButtons>
          </ButtonRow>
        </Footer>,
      ]}
    </Modal>
  )
}
export default AddTempOverridePatchModal
