const EN_US = {
  'roleManagementDomain.title': 'Roles',
  'roleManagementDomain.titleInfo.days': 'Last updated: {days} days ago',
  'roleManagementDomain.titleInfo.yesterday': 'Last updated: yesterday',
  'roleManagementDomain.titleInfo.today': 'Last updated: today',
  'roleManagementDomain.titleInfo.noHistory': 'Last updated: no history found',
  'roleManagementDomain.titleInfo.tooltip': 'View update history',
  'roleManagementDomain.searchRoles.title': 'All roles',
  'roleManagementDomain.searchRoles.subtitle':
    'View the permissions and assign users to our {defaultRolesURL}. You can create custom roles for your organization as needed. {readDocumentationURL}',
  'roleManagementDomain.searchRoles.subtitle.defaultRoles': 'default roles',
  'roleManagementDomain.searchRoles.subtitle.readDocumentation': 'Read documentation',
  'roleManagementDomain.searchRoles.filters.textInput': 'Search for a role',
  'roleManagementDomain.searchRoles.table.noResults': 'No roles found!',
  'roleManagementDomain.searchRoles.table.roleName': 'Role name',
  'roleManagementDomain.searchRoles.table.description': 'Description',
  'roleManagementDomain.searchRoles.table.userCount': 'Users',
  'roleManagementDomain.searchRoles.table.roleType': 'Role type',
  'roleManagementDomain.searchRoles.table.lastUpdated': 'Last updated',
  'roleManagementDomain.searchRoles.roleNotFound': 'Role not found',
  'roleManagementDomain.searchRoles.couldNotFindRoleX':
    'Sorry! The role {name} could not be found.',
  'roleManagementDomain.searchAccounts.filters.textInput': 'Search by email',
  'roleManagementDomain.searchAccounts.filters.accountStatus.placeholder': 'Status',
  'roleManagementDomain.searchAccounts.filters.accountStatus.active': 'Active',
  'roleManagementDomain.searchAccounts.filters.accountStatus.deactivated': 'Deactivated',
  'roleManagementDomain.searchAccounts.filters.accountStatus.pendingSetup': 'Pending Setup',
  'roleManagementDomain.searchAccounts.accountStatusActive': 'Active',
  'roleManagementDomain.searchAccounts.accountStatusDeactivated': 'Deactivated',
  'roleManagementDomain.searchAccounts.accountStatusPendingSetupDetails':
    'User has received account setup email with a link to set a password.',
  'roleManagementDomain.searchAccounts.accountStatusPendingSetup': 'Pending Setup',
  'roleManagementDomain.searchAccounts.headers.name': 'Name',
  'roleManagementDomain.searchAccounts.headers.email': 'Email',
  'roleManagementDomain.searchAccounts.headers.roles': 'Roles',
  'roleManagementDomain.searchAccounts.headers.status': 'Status',
  'roleManagementDomain.filters.roleType.placeholder': 'Role Type',
  'roleManagementDomain.filters.role.placeholder': 'Role',
  'roleManagementDomain.roleType.custom': 'Custom',
  'roleManagementDomain.roleType.default': 'Default',
  'roleManagementDomain.roleType.instacartInternal': 'Instacart Internal Only',
  'roleManagementDomain.actions.okay': 'Okay',
  'roleManagementDomain.actions.loading': 'Loading...',
  'roleManagementDomain.actions.checkItOut': 'Check it out',
  'roleManagementDomain.actions.needMoreInfo?': 'Need more info?',
  'roleManagementDomain.actions.goToDocumentation': 'Go to Documentation',
  'roleManagementDomain.role.actions.inviteNewUserSuffix': 'invite a new user',
  'roleManagementDomain.role.viewAssignedUsers': 'View assigned users',
  'roleManagementDomain.role.assignedUsers': 'Assigned Users',
  'roleManagementDomain.role.assignedUsersSubtitle1':
    'Here you can assign or unassign multiple users to a role. Search for an existing user by name, email, or title, or use the dropdown menu to filter by status. You can also',
  'roleManagementDomain.role.assignedUsersSubtitle2':
    'The new user will receive an email with instructions on how to activate their account.',
  'roleManagementDomain.role.assignedUsers.noUsersAssigned': 'No users assigned yet',
  'roleManagementDomain.role.assignedUsers.pleaseSearchForUserOr':
    'Please search for an existing user with the search bar above or',
  'roleManagementDomain.role.assignedUsers.missingManageUsersPermission':
    'You do not have permission to manage users, so you cannot view the assigned users to this role.',
  'roleManagementDomain.role.roleDetail': 'Role Detail',
  'roleManagementDomain.role.roleName': 'Role Name',
  'roleManagementDomain.role.description': 'Description',
  'roleManagementDomain.role.permissions': 'Permissions',
  'roleManagementDomain.role.internallyManagedDescription':
    'This is a predefined role managed by Instacart.',
  'roleManagementDomain.role.readOnlyRoleDescription':
    'The Read Only role does not have any permissions. Instead, it removes the users ability to perform updates to the system. This role is useful for users who need to view data but should not be able to make any changes.',

  'roleManagementDomain.errors.sorry': 'Sorry!',
  'roleManagementDomain.errors.unexpectedErrorDownloadingData':
    'There was an unexpected error while downloading this data. You may retry retrieving the data or contact support for assistance.',
  'roleManagementDomain.errors.createFailureNamed': 'Problem creating {name}.',
  'roleManagementDomain.errors.updateFailureNamed': 'Problem updating {name}.',
  'roleManagementDomain.errors.deleteFailureNamed': 'Problem deleting {name}.',
  'roleManagementDomain.errors.retryOrContactSupport':
    'You may retry or contact support for assistance.',
  'roleManagementDomain.errors.youDoNotHavePermissionToViewRole':
    'You do not have permission to view this role.',

  'roleManagementDomain.roleHistory.title': 'Roles history',
  'roleManagementDomain.roleHistory.header.action': 'Action',
  'roleManagementDomain.roleHistory.header.user': 'User',
  'roleManagementDomain.roleHistory.header.date': 'Date',
  'roleManagementDomain.roleHistory.emptyState': 'No history',
  'roleManagementDomain.roleHistory.action.createRole': 'Create new role: ',
  'roleManagementDomain.roleHistory.action.editRole': 'Edit role: ',
  'roleManagementDomain.roleHistory.action.deleteRole': 'Delete role: ',

  'roleManagementDomain.newPermissionBanner.text.top':
    'New permissions available and added to the Admin role! You can add the permissions to your custom roles.',
  'roleManagementDomain.newPermissionBanner.text.bottom': 'For more information, see ',
  'roleManagementDomain.newPermissionBanner.text.bottom.link': 'admin permission.',

  'roleManagementDomain.roleCreate.title': 'Create custom role',
  'roleManagementDomain.roleCreate.createRoleButton': 'Create role',
  'roleManagementDomain.roleDuplicate.duplicateRoleButton': 'Duplicate',
  'roleManagementDomain.roleEdit.title': 'Edit custom role',
  'roleManagementDomain.roleEdit.editRoleButton': 'Edit',
  'roleManagementDomain.roleEdit.assign': 'Assign',
  'roleManagementDomain.roleEdit.unassign': 'Unassign',

  'roleManagementDomain.roleDelete.deleteRoleButton': 'Delete',
  'roleManagementDomain.roleDelete.confirmation.title': 'Delete role',
  'roleManagementDomain.roleDelete.confirmation.checkbox.header': 'Delete this role.',
  'roleManagementDomain.roleDelete.confirmation.checkbox.label':
    'Unassign all users and remove the permissions connected to this role.',
  'roleManagementDomain.roleDelete.deleteRoleButton.tooltip':
    'Please confirm delete before continuing',
  'roleManagementDomain.roleDelete.success': 'Successfully deleted role {name}',

  'roleManagementDomain.manageRoleForm.required': 'This field is required',
  'roleManagementDomain.manageRoleForm.copy': 'copy',
  'roleManagementDomain.manageRoleForm.roleCreateSuccess': 'Successfully created role {name}',
  'roleManagementDomain.manageRoleForm.roleEditSuccess': 'Successfully updated role {name}',
  'roleManagementDomain.manageRoleForm.roleEditSummary.header': 'Update role',
  'roleManagementDomain.manageRoleForm.roleEditSummary.reviewChanges.removed':
    'Removing {numRolesRemoved} permissions',
  'roleManagementDomain.manageRoleForm.roleEditSummary.reviewChanges.added':
    'Adding {numRolesAdded} permissions',
  'roleManagementDomain.manageRoleForm.roleEditSummary.reviewChanges.confirmation':
    'Update permissions for this role and apply change to all assigned users',
  'roleManagementDomain.manageRoleForm.roleEditSummary.save': 'Update',
  'roleManagementDomain.manageRoleForm.roleEditSummary.save.tooltip':
    'Please confirm updates before continuing',
  'roleManagementDomain.manageRoleForm.details.title': 'Add role name and description',
  'roleManagementDomain.manageRoleForm.details.name.label': 'Role name',
  'roleManagementDomain.manageRoleForm.details.name.description': 'Give your role a name.',
  'roleManagementDomain.manageRoleForm.details.name.placeholder':
    'Can be a job title, team, department - or an acronym.',
  'roleManagementDomain.manageRoleForm.details.description.label': 'Description',
  'roleManagementDomain.manageRoleForm.details.description.description':
    'Help other Admins understand why you created this role.',
  'roleManagementDomain.manageRoleForm.details.description.placeholder':
    'Describe the purpose and functionality of this role.',
  'roleManagementDomain.manageRoleForm.permissions.title': 'Set permissions',
  'roleManagementDomain.manageRoleForm.permissions.subtitle':
    'Select permissions to assign to this role.',
  'roleManagementDomain.manageRoleForm.permissions.required': 'At least one permission is required',
  'roleManagementDomain.manageRoleForm.permissions.search.placeholder': 'Search for a permission',
  'roleManagementDomain.manageRoleForm.permissions.search.noPermissionsFound':
    'No permissions found',
  'roleManagementDomain.manageRoleForm.permissions.selector.subtitle': 'selected',
  'roleManagementDomain.manageRoleForm.footer.save': 'Save this role',
  'roleManagementDomain.manageRoleForm.footer.cancel': 'Cancel',
  'roleManagementDomain.manageRoleForm.footer.message':
    'Save this role. When you do this, all Admins can manage this role and add users instantly.',

  // announcement modal
  'roleManagementDomain.announcementModal.title': 'Introducing Users, Roles, and Permissions',
  'roleManagementDomain.announcementModal.whatsNew': "What's new?",
  'roleManagementDomain.announcementModal.permissions': 'Permissions.',
  'roleManagementDomain.announcementModal.permissionsDescription':
    'Permissions control access to specific features and tools. You can view the permissions that are assigned to Instacart’s predefined roles, or you can assign permissions to custom roles.',
  'roleManagementDomain.announcementModal.createCustomRoles': 'Create custom roles.',
  'roleManagementDomain.announcementModal.createCustomRolesDescription':
    'Custom roles give you more control over what your users can access. If Instacart’s predefined roles don’t suit your needs, create custom roles.',
  'roleManagementDomain.announcementModal.assignUsersToRoles': 'Assign roles to users.',
  'roleManagementDomain.announcementModal.assignUsersToRolesDescription':
    'Quickly and easily assign a role to multiple users without needing to go to each individual user account.',

  /**
   * DEV NOTE: Add your translated strings for your permissions below.
   */

  // ADMINISTRATION
  'roleManagementDomain.permissions.administration': 'Account management',
  // Basic access
  'roleManagementDomain.permissions.administration.basicAccess': 'Instacart Platform Portal',
  'roleManagementDomain.permissions.administration.basicAccess.description':
    'Access to the Instacart Platform Portal. This permission gives basic access to the Portal but does not give access to any tools within the Portal.',
  'roleManagementDomain.permissions.administration.basicAccess.basic.legacy_permission': 'Allow',
  // User accounts
  'roleManagementDomain.permissions.administration.userManagement': 'User accounts',
  'roleManagementDomain.permissions.administration.userManagement.description':
    'Manage user accounts and user access to the Instacart Platform Portal and to other Instacart services.',
  'roleManagementDomain.permissions.administration.userManagement.manage_users.legacy_permission':
    'Allow',
  // Role management
  'roleManagementDomain.permissions.administration.roleManagement': 'Roles and permissions',
  'roleManagementDomain.permissions.administration.roleManagement.description':
    'Manage custom roles and permissions to suit your user security requirements.',
  'roleManagementDomain.permissions.administration.roleManagement.role_management.view': 'View',
  'roleManagementDomain.permissions.administration.roleManagement.role_management.modify': 'Modify',
  // Audit logs
  'roleManagementDomain.permissions.administration.userActivity': 'Audit logs',
  'roleManagementDomain.permissions.administration.userActivity.description':
    'View logs to audit user actions within the Instacart Platform Portal.',
  'roleManagementDomain.permissions.administration.userActivity.audit_logs.view': 'Allow',
  // Warehouse Security
  'roleManagementDomain.permissions.administration.warehouseSecurity': 'Retailer Security',
  'roleManagementDomain.permissions.administration.warehouseSecurity.description':
    'Manage security settings for your retailer, e.g. requiring 2FA for all users.',
  'roleManagementDomain.permissions.administration.warehouseSecurity.warehouse_security.view':
    'View',
  'roleManagementDomain.permissions.administration.warehouseSecurity.warehouse_security.modify':
    'Modify',

  // DEVELOPERS
  'roleManagementDomain.permissions.developers': 'Developers',
  // Developer Dashboard
  'roleManagementDomain.permissions.developers.devPortal': 'Developer Dashboard',
  'roleManagementDomain.permissions.developers.devPortal.description':
    'Access to tools within the Developer Dashboard, such as the Fulfillment Testing Center and the Webhook Configuration tool.',
  'roleManagementDomain.permissions.developers.devPortal.dev_portal.view': 'Allow',
  // Developer Dashboard Admin
  'roleManagementDomain.permissions.developers.devAdmin': 'Developer Dashboard Admin',
  'roleManagementDomain.permissions.developers.devAdmin.description':
    'Administrator access to the Developer Dashboard. This includes the ability to simulate orders in the Fulfillment Testing Center tool and modify production configurations in the Webhook Configuration tool.',
  'roleManagementDomain.permissions.developers.devAdmin.dev_portal_admin.view': 'View',
  'roleManagementDomain.permissions.developers.devAdmin.dev_portal_admin.modify': 'Modify',
  // Webhook configuration
  'roleManagementDomain.permissions.developers.webhookConfig': 'Webhook configuration',
  'roleManagementDomain.permissions.developers.webhookConfig.description':
    'Manage webhook configurations to send callbacks to your site.',
  'roleManagementDomain.permissions.developers.webhookConfig.retailer_management.view': 'View',
  'roleManagementDomain.permissions.developers.webhookConfig.retailer_management.modify': 'Modify',
  // Connect Platform
  'roleManagementDomain.permissions.developers.connectPlatform': 'Platform data',
  'roleManagementDomain.permissions.developers.connectPlatform.description':
    'Access to Connect APIs for viewing or modifying platform data. This permission is needed for various pages, such as the tools in the Developer Dashboard and in last mile delivery pages.',
  'roleManagementDomain.permissions.developers.connectPlatform.connect_platform.view': 'View',
  'roleManagementDomain.permissions.developers.connectPlatform.connect_platform.modify': 'Modify',

  // ANALYTICS AND REPORTS
  'roleManagementDomain.permissions.analyticsAndReports': 'Analytics and reports',
  // Pickup analytics
  'roleManagementDomain.permissions.analyticsAndReports.pickupAnalytics': 'Pickup analytics',
  'roleManagementDomain.permissions.analyticsAndReports.pickupAnalytics.description':
    'Access associate performance KPIs for stores that are enrolled in the Instacart Pickup program.',
  'roleManagementDomain.permissions.analyticsAndReports.pickupAnalytics.pickup_analytics.legacy_permission':
    'Allow',
  // Insights Portal: Executive Summary
  'roleManagementDomain.permissions.analyticsAndReports.insightsExecutiveSummary':
    'Executive summary',
  'roleManagementDomain.permissions.analyticsAndReports.insightsExecutiveSummary.description':
    'Access an executive dashboard that aggregates performance metrics and shows leading or trailing KPIs.',
  'roleManagementDomain.permissions.analyticsAndReports.insightsExecutiveSummary.insights_portal.executive_summary':
    'Allow',
  // Insights Portal: Performance
  'roleManagementDomain.permissions.analyticsAndReports.insightsPerformance':
    'Performance dashboard',
  'roleManagementDomain.permissions.analyticsAndReports.insightsPerformance.description':
    'Access various dashboards and reports that include store performance KPIs such as GMV, basket size, and order ratings.',
  'roleManagementDomain.permissions.analyticsAndReports.insightsPerformance.insights_portal.performance':
    'Allow',
  // Insights Portal: Creatives
  'roleManagementDomain.permissions.analyticsAndReports.insightsPlacements': 'Creatives dashboard',
  'roleManagementDomain.permissions.analyticsAndReports.insightsPlacements.description':
    'Allow access to Creatives KPI dashboard',
  'roleManagementDomain.permissions.analyticsAndReports.insightsPlacements.insights_portal.placements':
    'Allow',
  // Insights Portal: Homepage
  'roleManagementDomain.permissions.analyticsAndReports.insightsHomepage': 'Homepage analytics',
  'roleManagementDomain.permissions.analyticsAndReports.insightsHomepage.description':
    'Access to view analytics data on the homepage.',
  'roleManagementDomain.permissions.analyticsAndReports.insightsHomepage.insights_portal.homepage':
    'View',
  // Custom reports
  'roleManagementDomain.permissions.analyticsAndReports.dataExplorer': 'Custom reports',
  'roleManagementDomain.permissions.analyticsAndReports.dataExplorer.description':
    'Access custom reports where you can choose which metrics to include in the report.',
  'roleManagementDomain.permissions.analyticsAndReports.dataExplorer.insights_portal.data_explorer':
    'View',
  // Ads insights
  'roleManagementDomain.permissions.analyticsAndReports.adsInsights': 'Ads dashboard',
  'roleManagementDomain.permissions.analyticsAndReports.adsInsights.description':
    'Access to the Ads Dashboard that includes KPIs related to the performance of Carrot Ads.',
  'roleManagementDomain.permissions.analyticsAndReports.adsInsights.insights_portal.view_ads_dashboard':
    'View',
  // Standard Reports
  'roleManagementDomain.permissions.analyticsAndReports.retailerReports': 'Standard reports',
  'roleManagementDomain.permissions.analyticsAndReports.retailerReports.description':
    'Access various business reports, such as top products, top categories, and store performance metrics. Note: The modify permission gives access to merging tax-related reports for a specified period.',
  'roleManagementDomain.permissions.analyticsAndReports.retailerReports.retailer_reports.view':
    'View',
  'roleManagementDomain.permissions.analyticsAndReports.retailerReports.retailer_reports.modify':
    'Modify',
  // Tax logs reports
  'roleManagementDomain.permissions.analyticsAndReports.retailerTaxLog': 'Tax log reports',
  'roleManagementDomain.permissions.analyticsAndReports.retailerTaxLog.description':
    'Access reports about retailer paid taxes on orders.',
  'roleManagementDomain.permissions.analyticsAndReports.retailerTaxLog.normalized_reports.RETAILER_TAX_LOGS':
    'Allow',
  // Taxes paid reports
  'roleManagementDomain.permissions.analyticsAndReports.taxesPaidAtPos': 'Taxes paid reports',
  'roleManagementDomain.permissions.analyticsAndReports.taxesPaidAtPos.description':
    'Access reports about taxes paid at point-of-sale.',
  'roleManagementDomain.permissions.analyticsAndReports.taxesPaidAtPos.normalized_reports.TAXES_PAID_AT_POS':
    'Allow',
  // Marketplace facilitator reports
  'roleManagementDomain.permissions.analyticsAndReports.mpfTaxesSemiMonthly':
    'Marketplace facilitator reports',
  'roleManagementDomain.permissions.analyticsAndReports.mpfTaxesSemiMonthly.description':
    'Access semi-monthly report for marketplace facilitator invoices.',
  'roleManagementDomain.permissions.analyticsAndReports.mpfTaxesSemiMonthly.normalized_reports.mpf_taxes_semi_monthly':
    'Allow',
  // Ads report
  'roleManagementDomain.permissions.analyticsAndReports.adsReport': 'Ads report',
  'roleManagementDomain.permissions.analyticsAndReports.adsReport.description':
    'Access reports about Carrot Ads on your storefront.',
  'roleManagementDomain.permissions.analyticsAndReports.adsReport.normalized_reports.ADS_REPORT':
    'Allow',
  // Prescription delivery reports
  'roleManagementDomain.permissions.analyticsAndReports.prescriptionDeliveryReports':
    'Prescription delivery reports',
  'roleManagementDomain.permissions.analyticsAndReports.prescriptionDeliveryReports.description':
    'Access logs for deliveries that include prescriptions.',
  'roleManagementDomain.permissions.analyticsAndReports.prescriptionDeliveryReports.rx_tlogs.legacy_warehouse_level_permission':
    'Allow',
  // Transaction logs
  'roleManagementDomain.permissions.analyticsAndReports.transactionLogs': 'Transaction logs',
  'roleManagementDomain.permissions.analyticsAndReports.transactionLogs.description':
    'Access logs for online and in-store transactions.',
  'roleManagementDomain.permissions.analyticsAndReports.transactionLogs.tlogs.legacy_warehouse_level_permission':
    'Allow',
  // Alcohol transaction logs
  'roleManagementDomain.permissions.analyticsAndReports.alcoholTransactionLogs': 'Alcohol logs',
  'roleManagementDomain.permissions.analyticsAndReports.alcoholTransactionLogs.description':
    'Access logs for deliveries that include alcohol.',
  'roleManagementDomain.permissions.analyticsAndReports.alcoholTransactionLogs.alcohol_tlogs.legacy_warehouse_level_permission':
    'Allow',

  // CATALOG
  'roleManagementDomain.permissions.catalog': 'Catalog',
  // Products
  'roleManagementDomain.permissions.catalog.products': 'Products',
  'roleManagementDomain.permissions.catalog.products.description':
    'Manage product details or request for new products.',
  'roleManagementDomain.permissions.catalog.products.catalog.legacy_permission': 'Allow',
  // Product images
  'roleManagementDomain.permissions.catalog.images': 'Product images',
  'roleManagementDomain.permissions.catalog.images.description':
    'Manage product images, including access to bulk upload images.',
  'roleManagementDomain.permissions.catalog.images.catalog_admin_image_management.view': 'View',
  'roleManagementDomain.permissions.catalog.images.catalog_admin_image_management.modify': 'Modify',
  // Code lookup
  'roleManagementDomain.permissions.catalog.codeLookup': 'Code lookup',
  'roleManagementDomain.permissions.catalog.codeLookup.description':
    'Have the ability to find products by using UPC or RRC codes.',
  'roleManagementDomain.permissions.catalog.codeLookup.code_lookup_batches.modify': 'Modify',
  // Retailer collections V2
  'roleManagementDomain.permissions.catalog.retailerCollectionsV2': 'Product collections',
  'roleManagementDomain.permissions.catalog.retailerCollectionsV2.description': `Manage product collections for your storefronts by using the Catalog > Collections tool. Note: This tool is available for select retailers.`,
  'roleManagementDomain.permissions.catalog.retailerCollectionsV2.retailer_collections.view': `View`,
  'roleManagementDomain.permissions.catalog.retailerCollectionsV2.retailer_collections.modify': `Modify`,

  // Category management

  'roleManagementDomain.permissions.categoryManagement': 'Category management',
  'roleManagementDomain.permissions.categoryManagement.brandExplorer': `Brand Explorer`,
  'roleManagementDomain.permissions.categoryManagement.brandExplorer.description':
    'Access to sell sheets created by brands containing key product and sales info.',
  'roleManagementDomain.permissions.categoryManagement.brandExplorer.emerging_brands_portal.modify':
    'Allow',

  // INTEGRATIONS
  'roleManagementDomain.permissions.integrations': 'Integration settings',
  // Manage integrations
  'roleManagementDomain.permissions.integrations.integrationsPage': 'Third party integrations',
  'roleManagementDomain.permissions.integrations.integrationsPage.description':
    'Manage third party integrations, such as Google Business.',
  'roleManagementDomain.permissions.integrations.integrationsPage.integrations.view': 'View',
  'roleManagementDomain.permissions.integrations.integrationsPage.integrations.modify': 'Modify',
  // Stripe
  'roleManagementDomain.permissions.integrations.stripeIntegration': 'Stripe',
  'roleManagementDomain.permissions.integrations.stripeIntegration.description':
    'Manage integrations with Stripe to handle payments.',
  'roleManagementDomain.permissions.integrations.stripeIntegration.stripe.legacy_permission':
    'Allow',

  // MERCHANDISING AND COLLECTIONS
  'roleManagementDomain.permissions.merchandisingAndCollections': 'Merchandising and collections',
  // Retailer collections
  'roleManagementDomain.permissions.merchandisingAndCollections.retailerCollections':
    'Retailer collections',
  'roleManagementDomain.permissions.merchandisingAndCollections.retailerCollections.description':
    'Manage collections that appear in your storefront’s navigation menu.',
  'roleManagementDomain.permissions.merchandisingAndCollections.retailerCollections.collections.view':
    'View',
  'roleManagementDomain.permissions.merchandisingAndCollections.retailerCollections.collections.modify':
    'Modify',
  // Promotional Banners
  'roleManagementDomain.permissions.merchandisingAndCollections.promotionalBanners':
    'Promotional banners',
  'roleManagementDomain.permissions.merchandisingAndCollections.promotionalBanners.description':
    'Access to the Promotional Banners tool.',
  'roleManagementDomain.permissions.merchandisingAndCollections.promotionalBanners.promoted_placements.legacy_permission':
    'Allow',
  // Placements
  'roleManagementDomain.permissions.merchandisingAndCollections.placements': 'Placements',
  'roleManagementDomain.permissions.merchandisingAndCollections.placements.description':
    'Manage homepage banner placements within the Promotional Banners tool.',
  'roleManagementDomain.permissions.merchandisingAndCollections.placements.content_management_storefront_placements.view':
    'View',
  'roleManagementDomain.permissions.merchandisingAndCollections.placements.content_management_storefront_placements.modify':
    'Modify',

  // STOREFRONT
  'roleManagementDomain.permissions.storefront': 'Storefront',

  // Storefront management
  'roleManagementDomain.permissions.storefront.storefrontManagement': 'Storefront management',
  'roleManagementDomain.permissions.storefront.storefrontManagement.description':
    'Manage general storefront (site) settings that is not specific to a feature below. This is a base permission to access any storefront-related tools.',
  'roleManagementDomain.permissions.storefront.storefrontManagement.store_configs.view': 'View',
  'roleManagementDomain.permissions.storefront.storefrontManagement.store_configs.modify': 'Modify',

  // Autosuggest links
  'roleManagementDomain.permissions.storefront.autosuggestLinks': 'Keyword redirects',
  'roleManagementDomain.permissions.storefront.autosuggestLinks.description':
    'Manage links inserted into the search typeahead dropdown.',
  'roleManagementDomain.permissions.storefront.autosuggestLinks.autosuggest_links.view': 'View',
  'roleManagementDomain.permissions.storefront.autosuggestLinks.autosuggest_links.modify': 'Modify',

  // Pages
  'roleManagementDomain.permissions.storefront.pages': 'Pages',
  'roleManagementDomain.permissions.storefront.pages.description':
    'Manage custom pages on your storefront.',
  'roleManagementDomain.permissions.storefront.pages.page_builder.view': 'View',
  'roleManagementDomain.permissions.storefront.pages.page_builder.modify': 'Modify',

  // Creative Manager
  'roleManagementDomain.permissions.storefront.creativeManager': 'Creative Manager',
  'roleManagementDomain.permissions.storefront.creativeManager.description':
    'Access to creatives in the creative manager.',
  'roleManagementDomain.permissions.storefront.creativeManager.creative_manager.view': 'View',
  'roleManagementDomain.permissions.storefront.creativeManager.creative_manager.modify': 'Modify',

  // Department Navigation
  'roleManagementDomain.permissions.storefront.retailerCollectionsV2': 'Department navigation',
  'roleManagementDomain.permissions.storefront.retailerCollectionsV2.description': `Manage departments for your storefronts by using the Storefront > Navigation > Departments tool.`,
  'roleManagementDomain.permissions.storefront.retailerCollectionsV2.retailer_collections_departments.view': `View`,
  'roleManagementDomain.permissions.storefront.retailerCollectionsV2.retailer_collections_departments.modify': `Modify`,

  // NOTIFICATIONS
  'roleManagementDomain.permissions.notifications': 'Notifications',
  // Subscription management
  'roleManagementDomain.permissions.notifications.admin': 'Subscription management',
  'roleManagementDomain.permissions.notifications.admin.description':
    'Manage subscriptions on behalf of users.',
  'roleManagementDomain.permissions.notifications.admin.notifications.full_scope': 'Allow',
  // Insights summary
  'roleManagementDomain.permissions.notifications.insightsSummaryReport': 'Insights summary',
  'roleManagementDomain.permissions.notifications.insightsSummaryReport.description':
    'Allow user to self-subscribe to weekly analytics reports for business insights and performance metrics.',
  'roleManagementDomain.permissions.notifications.insightsSummaryReport.notifications.insights-summary-report':
    'Allow',
  // Inventory file normalization
  'roleManagementDomain.permissions.notifications.inventoryFileNormalization':
    'Inventory file normalization',
  'roleManagementDomain.permissions.notifications.inventoryFileNormalization.description':
    'Allow user to self-subscribe to notifications for inventory file normalization.',
  'roleManagementDomain.permissions.notifications.inventoryFileNormalization.notifications.inventory-file-normalization':
    'Allow',
  // Marketplace facilitator validation errors
  'roleManagementDomain.permissions.notifications.mpfTaxFileNotifications':
    'Marketplace facilitator validation errors',
  'roleManagementDomain.permissions.notifications.mpfTaxFileNotifications.description':
    'Allow user to self-subscribe to notifications for marketplace facilitator tax-feed validation errors.',
  'roleManagementDomain.permissions.notifications.mpfTaxFileNotifications.notifications.mpf-tax-file-notifications':
    'Allow',
  // File ingestion critical data validation
  'roleManagementDomain.permissions.notifications.fileIngestionCriticalDataValidation':
    'File ingestion critical data validation',
  'roleManagementDomain.permissions.notifications.fileIngestionCriticalDataValidation.description':
    'Allow user to self-subscribe to notifications for critical data validations during file ingestion.',
  'roleManagementDomain.permissions.notifications.fileIngestionCriticalDataValidation.notifications.file-ingestion-critical-data-validation':
    'Allow',
  // Daily file ingestion data validation
  'roleManagementDomain.permissions.notifications.dailyFileIngestionDataValidation':
    'Daily file ingestion data validation',
  'roleManagementDomain.permissions.notifications.dailyFileIngestionDataValidation.description':
    'Allow user to self-subscribe to daily notifications for data validations during file ingestion.',
  'roleManagementDomain.permissions.notifications.dailyFileIngestionDataValidation.notifications.daily-file-ingestion-data-validation':
    'Allow',
  // Weekly file ingestion data validation
  'roleManagementDomain.permissions.notifications.weeklyFileIngestionDataValidation':
    'Weekly file ingestion data validation',
  'roleManagementDomain.permissions.notifications.weeklyFileIngestionDataValidation.description':
    'Allow user to self-subscribe to weekly notifications for data validations during file ingestion.',
  'roleManagementDomain.permissions.notifications.weeklyFileIngestionDataValidation.notifications.weekly-file-ingestion-data-validation':
    'Allow',
  // Store hour updates
  'roleManagementDomain.permissions.notifications.storeHourUpdates': 'Store hour updates',
  'roleManagementDomain.permissions.notifications.storeHourUpdates.description':
    'Allow user to self-subscribe to notifications for changes to store hours.',
  'roleManagementDomain.permissions.notifications.storeHourUpdates.notifications.store-hour-updates':
    'Allow',
  // Holiday hours
  'roleManagementDomain.permissions.notifications.upcomingStoreHoursReminder': 'Holiday hours',
  'roleManagementDomain.permissions.notifications.upcomingStoreHoursReminder.description':
    'Allow user to self-subscribe to notifications for changes to holiday hours.',
  'roleManagementDomain.permissions.notifications.upcomingStoreHoursReminder.notifications.upcoming-store-hours-reminder':
    'Allow',

  // ORDERS
  'roleManagementDomain.permissions.orders': 'Orders',
  // Last mile delivery
  'roleManagementDomain.permissions.orders.lastMileDelivery': 'Last mile delivery',
  'roleManagementDomain.permissions.orders.lastMileDelivery.description':
    'Manage last mile delivery orders made through Connect APIs.',
  'roleManagementDomain.permissions.orders.lastMileDelivery.connect_orders.view': 'View',
  'roleManagementDomain.permissions.orders.lastMileDelivery.connect_orders.modify': 'Modify',
  // Last mile delivery order details
  'roleManagementDomain.permissions.orders.lastMileDeliveryDetails':
    'Last mile delivery order details',
  'roleManagementDomain.permissions.orders.lastMileDeliveryDetails.description':
    'View last mile delivery order details.',
  'roleManagementDomain.permissions.orders.lastMileDeliveryDetails.retailers_v4.view': 'View',
  // Delivery
  'roleManagementDomain.permissions.orders.delivery': 'Delivery',
  'roleManagementDomain.permissions.orders.delivery.description': 'View delivery order details.',
  'roleManagementDomain.permissions.orders.delivery.orders_delivery.legacy_permission': 'View',
  // Pickup
  'roleManagementDomain.permissions.orders.pickup': 'Pickup',
  'roleManagementDomain.permissions.orders.pickup.description': 'View pickup order details.',
  'roleManagementDomain.permissions.orders.pickup.orders_pickup.legacy_permission': 'View',
  // Operational Dashboard
  'roleManagementDomain.permissions.orders.orders': 'Operational Dashboard',
  'roleManagementDomain.permissions.orders.orders.description':
    'Access to search across all order types by using the Operational Dashboard tool.',
  'roleManagementDomain.permissions.orders.orders.orders_v4.view': 'View',
  'roleManagementDomain.permissions.orders.orders.orders_v4.modify': 'Modify',
  'roleManagementDomain.permissions.orders.ordersOperationalDashboardCancel':
    'Operational Dashboard Cancel Order',
  'roleManagementDomain.permissions.orders.ordersOperationalDashboardCancel.description':
    'Access to cancel orders from the Order Operational Dashboard if there are policies that allow for cancellation.',
  'roleManagementDomain.permissions.orders.ordersOperationalDashboardCancel.orders_operational_dashboard.cancel':
    'Allow',
  'roleManagementDomain.permissions.orders.ordersOperationalDashboardReschedule':
    'Operational Dashboard Reschedule Order',
  'roleManagementDomain.permissions.orders.ordersOperationalDashboardReschedule.description':
    'Access to reschedule orders from the Order Operational Dashboard if there are policies that allow for rescheduling.',
  'roleManagementDomain.permissions.orders.ordersOperationalDashboardReschedule.orders_operational_dashboard.reschedule':
    'Allow',

  // STORES
  'roleManagementDomain.permissions.stores': 'Stores',
  // Staff performance dashboard
  'roleManagementDomain.permissions.stores.staffPerformanceDashboard': 'Staffing performance',
  'roleManagementDomain.permissions.stores.staffPerformanceDashboard.description':
    'Access to the Staffing Performance dashboard.',
  'roleManagementDomain.permissions.stores.staffPerformanceDashboard.store_performance.view':
    'View',
  // Staffing performance data
  'roleManagementDomain.permissions.stores.staffPerformanceData': 'Performance metrics',
  'roleManagementDomain.permissions.stores.staffPerformanceData.description':
    'Access store and staff performance metrics in the Staffing Performance dashboard.',
  'roleManagementDomain.permissions.stores.staffPerformanceData.historical_store_performance.view':
    'View',
  // Store hours
  'roleManagementDomain.permissions.stores.warehouseLocationHours': 'Store hours',
  'roleManagementDomain.permissions.stores.warehouseLocationHours.description':
    'Manage store regular hours and special hours, such as holiday hours.',
  'roleManagementDomain.permissions.stores.warehouseLocationHours.warehouse_location_hours.view':
    'View',
  'roleManagementDomain.permissions.stores.warehouseLocationHours.warehouse_location_hours.modify':
    'Modify',
  // Store groups
  'roleManagementDomain.permissions.stores.retailerLocationGroups': 'Store Groups',
  'roleManagementDomain.permissions.stores.retailerLocationGroups.description':
    'Manage store groups. Store groups can be used to filter data related to a set of stores.',
  'roleManagementDomain.permissions.stores.retailerLocationGroups.retailer_location_groups.view':
    'View',
  'roleManagementDomain.permissions.stores.retailerLocationGroups.retailer_location_groups.modify':
    'Modify',
  // Device accounts
  'roleManagementDomain.permissions.stores.shifts': 'Device accounts',
  'roleManagementDomain.permissions.stores.shifts.description':
    'Manage device accounts that can access the In-store Associate app.',
  'roleManagementDomain.permissions.stores.shifts.manage_shoppers.legacy_permission': 'Allow',
  // Partner pick
  'roleManagementDomain.permissions.stores.partnerPick': 'Shifts',
  'roleManagementDomain.permissions.stores.partnerPick.description':
    'Manage shifts for pickers and runners.',
  'roleManagementDomain.permissions.stores.partnerPick.partner_pick.view': 'View',
  'roleManagementDomain.permissions.stores.partnerPick.partner_pick.modify': 'Modify',
  // Pickup settings
  'roleManagementDomain.permissions.stores.shoppers': 'Pickup area',
  'roleManagementDomain.permissions.stores.shoppers.description':
    'Manage pickup area settings such as defining shelf labels for the staging area or creating pickup instructions for customers.',
  'roleManagementDomain.permissions.stores.shoppers.shoppers_retailer_settings.view': 'View',
  'roleManagementDomain.permissions.stores.shoppers.shoppers_retailer_settings.modify': 'Modify',
} as const

export default EN_US
