import { FeatureToggle } from './access-control/featureToggles'
import { Permission } from './access-control/permissions'
import { legacyDepartmentsAccessControl } from './access-control/retailerCollectionsV2Access.configuration'
import { useDomainAccessControl } from './utils/domain/accessControl'
import { useDomainGoToPath } from './utils/domain/routing'

export {
  useSearchRetailerProducts,
  useSearchRetailerProductsV2,
} from './hooks/useSearchRetailerProducts.hooks'
export { useDomainGetScopedPathname as useRetailerCollectionsGetScopedPathname } from './utils/domain/routing'
export { EducationBannerBuilder } from './components/education/EducationBannerBuilder'
export { useGoToV2RetailerCollections } from './utils/useGoToRetailerCollections.hooks'
export { default as useRetailerCollectionsVersion } from './hooks/useRetailerCollectionsVersion'
export { EducationBanner as CollectionsEducationBanner } from './components/education/EducationBanner'
export const useHasRetailerCollectionsAccess = () =>
  useDomainAccessControl()({
    permissions: [Permission.RetailerCollectionsV2],
  })

export const retailerCollectionsLegacyDepartmentsAislesAccessControl =
  legacyDepartmentsAccessControl

export const useHasRetailerCollectionsDepartmentsAislesAccess = () =>
  useDomainAccessControl()(retailerCollectionsLegacyDepartmentsAislesAccessControl)

export const useGoToRetailerCollectionsCreate = () =>
  useDomainGoToPath('retailer-collections-create')

export * from './types'

export { default as HierarchicalSelect } from './components/form/components/dynamic-filter-selector/HierarchicalSelect'
