import { ICPlatformVersion } from '@retailer-platform/dashboard/access-control'
import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { FeatureEntitlements } from './featureEntitlements'

export const campaignManagementAccessControl: DomainAccessControlConfig = {}

export const pageBuilderPlacementTargetingAccessControl: DomainAccessControlConfig = {
  featureEntitlementIds: [FeatureEntitlements.PageBuilderPlacementTargeting],
}

export const colorOverlayAccessControl: DomainAccessControlConfig = {
  minICPlatformVersion: ICPlatformVersion.r2024_10_0,
}
