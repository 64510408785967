import { useRetailersStoreConfigurations } from '@retailer-platform/dashboard/api'
import { useOptionalWarehouseContext } from '@retailer-platform/dashboard/utils'
import { useParams } from 'react-router'
import { clientEnv } from '@retailer-platform/dashboard/env'
import { StoreConfigurationRelationshipEnum } from '../../../api'

const STG_URL = 'https://web-instacart-customers-stg.instacart.team'
const LOCALE_DOMAIN_MAP = {
  CA: 'https://instacart.ca',
  US: 'https://instacart.com',
}

export const useCollectionPreview = () => {
  const storeConfigData = useRetailersStoreConfigurations({
    storeConfigRelationship: StoreConfigurationRelationshipEnum.Associated,
    throwOnMissing: false,
  })

  const localeCode = useOptionalWarehouseContext()?.warehouse?.locales?.[0].localeCode
  const country = localeCode?.split('_')[1] || ''
  const { slug: warehouseSlug } = useOptionalWarehouseContext()?.warehouse || {}
  const env = clientEnv.PUBLIC_CLIENT_ENVIRONMENT
  const params = useParams<{ storeConfigId?: string }>()

  const getDomainName = () => {
    if (params?.storeConfigId) {
      const storeConfig = storeConfigData.data?.storeConfigurationsByRetailerIds?.find(
        sc => sc.id === params.storeConfigId
      )
      return storeConfig?.domainName || ''
    }

    if (['development', 'staging'].includes(env)) {
      return STG_URL
    }

    return LOCALE_DOMAIN_MAP[country as keyof typeof LOCALE_DOMAIN_MAP] ?? LOCALE_DOMAIN_MAP.US
  }

  const getCollectionPreviewUrl = (collectionSlug: string) => {
    if (!collectionSlug) {
      console.warn('Collection slug not found')
      return ''
    }

    const domainName = getDomainName()
    return `${domainName}/store/${warehouseSlug}/collections/${collectionSlug}`
  }

  const openCollectionPreview = (collectionSlug: string) => {
    const url = getCollectionPreviewUrl(collectionSlug)
    if (url) {
      window.open(url, '_blank')
    }
  }

  return { openCollectionPreview, getCollectionPreviewUrl }
}
